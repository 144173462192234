import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import { DownloadFileFormModalType, PageDocument } from '../../../types/types';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ButtonLink from '../ButtonLink';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './BooksModule.module.scss';

interface Book {
  title: string;
  subtitle: string;
  mobileImage: SanityImageType;
  flatMockupCover: SanityImageType;
  page?: PageDocument;
  modal: DownloadFileFormModalType;
}

export type BooksModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  text: string;
  books: Array<Book>;
  className?: string;
} & (
  | {
      booksToUse: 'all';
      books?: never;
    }
  | {
      booksToUse: 'chooseManually';
      books: Array<Book>;
    }
);

interface QueryData {
  allSanityBook: {
    nodes: Array<Book>;
  };
}

export function getModuleBgColor(props: BooksModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function BooksModule(props: BooksModuleProps & CommonModuleProps): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityBook(sort: { orderRank: ASC }) {
        nodes {
          ...Book
        }
      }
    }
  `);

  const {
    backgroundColor,
    title,
    text,
    booksToUse,
    books,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  const booksToShow = booksToUse === 'chooseManually' ? books : staticData.allSanityBook.nodes;

  function renderBook(book: Book, rowLayout?: boolean) {
    return (
      <div className={clsx(styles.bookContainer, rowLayout && styles.rowLayout)}>
        <div className={styles.imageContainer}>
          <Image
            image={book.mobileImage}
            alt={book.title + ' Book'}
            className={styles.bookMobileImage}
          />
          <Image
            image={book.flatMockupCover}
            alt={book.title + ' Book'}
            className={styles.bookDesktopImage}
          />
        </div>
        <div className={styles.textContainer}>
          <h3 className={styles.bookTitle}>{book.title}</h3>
          <p className={styles.bookDescription}>{book.subtitle}</p>
          {(book.page || book.modal) && (
            <div className={styles.buttonsContainer}>
              {book.page?.slug?.current && (
                <ButtonLink
                  outlined
                  to={{ pageReference: book.page }}
                  linkClassName={styles.buttonContainer}
                  className={styles.button}
                >
                  Read more
                </ButtonLink>
              )}
              <ButtonLink
                to={{ downloadFileFormModal: book.modal }}
                linkClassName={styles.buttonContainer}
                modalFormButtonClassName={styles.buttonContainer}
                className={styles.button}
              >
                Download book
              </ButtonLink>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      title={title}
      text={text}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
    >
      {booksToShow.length > 2 ? (
        <div className={styles.booksContainer}>
          <div className={styles.firstBookContainer}>{renderBook(booksToShow[0])}</div>
          <div className={styles.otherBooksContainer}>
            <h3 className={styles.otherBooksTitle}>Other books</h3>
            <div className={clsx(styles.booksWrapper, styles.rowBooks)}>
              {booksToShow.slice(1).map((book, i) => (
                <React.Fragment key={i}>
                  {renderBook(book, true)}
                  <div className={clsx(styles.divider, styles.fullWidth)}></div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.booksWrapper}>
          {booksToShow.map((book, i) => (
            <React.Fragment key={i}>
              {renderBook(book)}
              <div className={styles.divider}></div>
            </React.Fragment>
          ))}
        </div>
      )}
    </ModuleLayout>
  );
}

export default BooksModule;
