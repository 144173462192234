import { graphql } from 'gatsby';
import { VersatileLink } from '../types/types';

export const NavigationMenuFragment = graphql`
  fragment NavigationMenu on SanityLinkNestedEntryOrLinkSingleEntry {
    __typename
    ... on SanityLinkSingleEntry {
      title
      pageReference {
        ...PageReference
      }
      anchorLink
      url
    }
    ... on SanityLinkNestedEntry {
      title
      entries {
        title
        subtitle
        pageReference {
          ...PageReference
        }
        anchorLink
        url
      }
    }
  }
`;

export type SingleEntry = {
  title: string;
} & VersatileLink;

export type SingleEntryWithSubtitle = SingleEntry & { subtitle?: string };

export interface NestedEntry {
  title: string;
  entries: Array<SingleEntryWithSubtitle>;
}

export type NavigationMenu = Array<SingleEntry | NestedEntry>;

export function isNestedEntry(entry: SingleEntry | NestedEntry): entry is NestedEntry {
  return 'entries' in entry;
}
