import { useInView } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { RiPlayFill } from 'react-icons/ri';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import { withDataLayer } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import EmbedModal from '../EmbedModal';
import ModalOverlay from '../ModalOverlay';
import ModuleLayout from '../ModuleLayout';
import * as styles from './VideoReportsModule.module.scss';

export interface VideoReportsModuleProps {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  videoReports: Array<{
    title: string;
    subtitle: string;
    image: SanityImageType;
    videoUrl: string;
  }>;
  className?: string;
}

export function getModuleBgColor(props: VideoReportsModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function VideoReportsModule(
  props: VideoReportsModuleProps & CommonModuleProps,
): React.ReactElement {
  const { backgroundColor, title, videoReports, className, moduleId, previousModuleBgColor } =
    props;

  const [modalOpenIndex, setModalOpenIndex] = useState<number | null>(null);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      title={title}
    >
      <div className={styles.videoReportsContainer} ref={ref}>
        {videoReports.map((videoReport, i) => (
          <div
            className={styles.videoReportContainer}
            key={i}
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(11, 33, 56, 0) 28.65%, #0B2138 85.42%), url(${videoReport.image.asset.url}?auto=format&q=75&h=1260)`,
              transform: isInView ? 'none' : `translateX(100vw)`,
              opacity: isInView ? 1 : 0,
              transition: `transform 0.6s cubic-bezier(0.17, 0.55, 0.55, 1) calc(${
                i * 0.2
              }s), opacity 0.6s cubic-bezier(0.17, 0.55, 0.55, 1) calc(${
                i * 0.2
              }s), background-size 0.3s ease`,
            }}
            onClick={() => {
              setModalOpenIndex(i);
              withDataLayer(dataLayer => {
                dataLayer.push({
                  event: 'video-report-open',
                  videoReportTitle: videoReport.title,
                });
              });
            }}
          >
            <div className={styles.playIconContainer}>
              <div className={styles.playIconOverlay}></div>
              <div className={styles.playIconWrapper}>
                <RiPlayFill className={styles.icon} aria-label="play" role="img" />
              </div>
            </div>
            <h3 className={styles.name}>{videoReport.title}</h3>
            <div className={styles.nameDivider}></div>
            <p className={styles.subtitle}>"{videoReport.subtitle}"</p>
            <ModalOverlay open={i === modalOpenIndex} onClose={() => setModalOpenIndex(null)}>
              <EmbedModal url={videoReport.videoUrl} />
            </ModalOverlay>
          </div>
        ))}
      </div>
    </ModuleLayout>
  );
}

export default VideoReportsModule;
