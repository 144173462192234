// extracted by mini-css-extract-plugin
export var container = "Video-module--container--125a8";
export var fitContent = "Video-module--fitContent--4ba58";
export var icon = "Video-module--icon--41140";
export var iconOnTopLeftCorner = "Video-module--iconOnTopLeftCorner--b5509";
export var iconWrapper = "Video-module--iconWrapper--063b3";
export var imageContainer = "Video-module--imageContainer--194e5";
export var overlay = "Video-module--overlay--0d36c";
export var thumbnail = "Video-module--thumbnail--8931c";
export var verticalVideo = "Video-module--verticalVideo--2bf50";
export var youtubeThumbnail = "Video-module--youtubeThumbnail--e3526";