import BlockContent from '@sanity/block-content-to-react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Form, { FormFieldWithId } from '../components/ui/Form';
import ModalOverlay from '../components/ui/ModalOverlay';
import { getSerializers } from '../serializers';
import { DownloadFileFormModalType } from '../types/types';
import { withDataLayer } from '../utils/utils';
import { LocationContext } from './LocationContext';
import * as styles from './ModalFormContext.module.scss';

interface ModalFormValue {
  showModalForm: (
    newModalFormOptions: {
      modalForm: DownloadFileFormModalType;
      formFieldsWithIds: Array<FormFieldWithId>;
    } | null,
  ) => void;
}

// We assume this ModalFormContext will never be used unless inside
// the ModalFormContext.Provider, and so the default is never used.
const ModalFormContext = createContext<ModalFormValue>({} as ModalFormValue);

export const useModalForm = () => useContext(ModalFormContext);

interface ModalFormProviderProps {
  children: React.ReactNode;
}

export function ModalFormProvider({ children }: ModalFormProviderProps) {
  const location = useContext(LocationContext)!;
  const [modalFormOptions, setModalFormOptions] = useState<{
    modalForm: DownloadFileFormModalType;
    formFieldsWithIds: Array<FormFieldWithId>;
  } | null>(null);

  function showModalForm(
    newModalFormOptions: {
      modalForm: DownloadFileFormModalType;
      formFieldsWithIds: Array<FormFieldWithId>;
    } | null,
  ) {
    setModalFormOptions(newModalFormOptions);
    window.history.pushState({}, '');
  }

  useEffect(() => {
    const handleBackButton = event => {
      if (modalFormOptions !== null) {
        event.preventDefault();
        setModalFormOptions(null);
      }
    };
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [modalFormOptions]);

  const fileUrl = modalFormOptions
    ? modalFormOptions.modalForm.fileToDownloadAfterSubmit?.asset.url +
      '?dl=' +
      modalFormOptions.modalForm.fileToDownloadAfterSubmit.asset.originalFilename
    : '';

  return (
    <ModalFormContext.Provider value={{ showModalForm }}>
      <ModalOverlay
        open={!!modalFormOptions}
        onClose={() => {
          // setModalFormOptions(null);
          window.history.back();
        }}
        modalClassName={styles.modal}
      >
        {modalFormOptions &&
          modalFormOptions.formFieldsWithIds &&
          modalFormOptions.formFieldsWithIds.length > 0 && (
            <Form
              fields={modalFormOptions.formFieldsWithIds!}
              title={modalFormOptions.modalForm.title}
              superTitle={modalFormOptions.modalForm.supertitle}
              text={modalFormOptions.modalForm._rawText}
              submitButtonText={modalFormOptions.modalForm.submitButtonText}
              withSmallerFontSize
              formType="modal"
              sanityDocumentId={modalFormOptions.modalForm._id}
              fileUrlToDownloadAfterSubmit={fileUrl}
              showNotificationWhenFormIsSubmitted
              notificationMessage={
                <BlockContent
                  renderContainerOnSingleChild
                  blocks={modalFormOptions.modalForm._rawSuccessMessage}
                  serializers={getSerializers(fileUrl)}
                />
              }
              className={styles.form}
              onSubmit={() => {
                withDataLayer(dataLayer => {
                  dataLayer.push({
                    event: 'form-submit',
                    type: 'modal-form',
                    id: modalFormOptions.modalForm.slug.current,
                    path: location.pathname,
                  });
                });
              }}
            />
          )}
      </ModalOverlay>
      {children}
    </ModalFormContext.Provider>
  );
}
