import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React from 'react';
import { CASE_STUDIES_PREFIX_URL } from '../../../constants';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { CaseStudy } from '../../../templates/CaseStudyPage';
import { getPortableTextAsString } from '../../../utils/sanity';
import { urlJoin } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ItemListing from '../ItemListing';
import ModuleLayout from '../ModuleLayout';
import * as styles from './CaseStudiesListingModule.module.scss';

export type CaseStudiesListingModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  withPagination?: boolean;
  itemWithTallerImage?: boolean;
  hideImageOnMobile?: boolean;
  className?: string;
} & (
  | {
      referencesToUse: 'all';
      caseStudyReferences?: never;
    }
  | {
      referencesToUse: 'chooseManually';
      caseStudyReferences: Array<CaseStudy>;
    }
);

interface QueryData {
  allSanityCaseStudy: {
    nodes: Array<CaseStudy>;
  };
}

export function getModuleBgColor(props: CaseStudiesListingModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function CaseStudiesListingModule(
  props: CaseStudiesListingModuleProps & CommonModuleProps,
): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityCaseStudy: allSanityHpWebsiteCaseStudy(sort: { orderRank: ASC }) {
        nodes {
          ...CaseStudy
        }
      }
    }
  `);

  const groqQuery = groq`{
    "allSanityCaseStudy": {
      "nodes": *[_type == "hp-website-caseStudy"] | order(orderRank asc) {
        ...
      }
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const {
    backgroundColor,
    title,
    referencesToUse,
    caseStudyReferences,
    withPagination,
    itemWithTallerImage,
    className,
    moduleId,
    previousModuleBgColor,
    hideImageOnMobile,
  } = props;

  const listingItems = (
    referencesToUse === 'chooseManually' && caseStudyReferences
      ? caseStudyReferences
      : data.allSanityCaseStudy.nodes
  ).map(caseStudy => {
    return {
      _id: caseStudy._id,
      image: caseStudy.image,
      title: caseStudy.title,
      text: getPortableTextAsString(caseStudy._rawDescription),
      category: caseStudy.category?.title,
      link: { url: urlJoin(CASE_STUDIES_PREFIX_URL, caseStudy.slug.current) },
    };
  });

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      title={title}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      childrenClassName={styles.content}
    >
      <div className={styles.contentContainer}>
        <ItemListing
          items={listingItems}
          withPagination={referencesToUse === 'all' || withPagination}
          itemWithTallerImage={itemWithTallerImage}
          hideImageOnMobile={hideImageOnMobile}
        />
      </div>
    </ModuleLayout>
  );
}

export default CaseStudiesListingModule;
