import React from 'react';
import { FaSpinner } from 'react-icons/fa';

import { addContactToKeap } from '../../api';
import { useForm, useFormField } from '../../utils/forms';
import { useTrackingData } from '../../utils/hooks';
import { clsx } from '../../utils/utils';
import * as styles from './EmailInput.module.scss';
import InputField from './InputField';

interface EmailInputProps {
  buttonReadyText: string;
  buttonSubmittingText: string;
  buttonSubmittedText: string;
  className?: string;
  onSubmit?: () => void;
}

const EmailInput = ({
  buttonReadyText,
  buttonSubmittedText,
  buttonSubmittingText,
  className,
  onSubmit,
}: EmailInputProps): React.ReactElement => {
  const trackingData = useTrackingData();

  const fieldsByName = {
    email: useFormField<string>('', ['required', 'email']),
  } as const;

  const { getFieldProps, renderSubmitButton, renderFormMessage, submitState } = useForm({
    fieldsByName,
    onSubmit: internalOnSubmit,
    translateFunction: key => {
      return {
        'form.required_field_error': 'Please fill in the field above',
        'form.invalid_email_error': 'Invalid email (e.g. email@example.com)',
        'form.network_error': 'Network failed to send your request.',
        'form.unknown_error': 'An unexpected error occured. Please try again later.',
        'form.success_message': 'Newsletter subscribed successfully!',
      }[key];
    },
  });

  async function internalOnSubmit() {
    const success = await addContactToKeap({
      formType: 'newsletter',
      email: fieldsByName.email.value!.trim(),
      trackingData,
    });
    if (success) {
      if (onSubmit) {
        onSubmit();
      }
    }
    return success;
  }

  return (
    <div className={clsx(styles.formContainer, className)}>
      <form name="contact" data-netlify="true" data-netlify-honeypot="hidden-field">
        <input type="hidden" name="newsletter" />
        <div className={styles.inputContainer}>
          <label className={styles.label} htmlFor="email-input">
            Email
          </label>
          <div className={clsx(styles.inputWrapper, fieldsByName.email.error && styles.error)}>
            <InputField
              className={clsx(styles.input)}
              type="email"
              id="email-input"
              name="email"
              helperTextClass={styles.helperText}
              {...getFieldProps(fieldsByName.email)}
            />
            {renderFormMessage({
              styles: {
                formMessage: styles.formMessage,
                formMessageSuccess: styles.formMessageSuccess,
                formMessageError: styles.formMessageError,
              },
            })}
          </div>
        </div>
        {renderSubmitButton({
          labels: {
            ready: buttonReadyText,
            submitting: buttonSubmittingText,
            submitted: buttonSubmittedText,
          },
          btnClasses: {
            common: styles.submitButton,
            ready: styles.formReady,
            submitting: styles.formSubmitting,
            submitted: styles.formSubmitted,
          },
          childrenBefore: submitState === 'submitting' && (
            <FaSpinner className={clsx(styles.buttonIcon, styles.buttonIconLoading)}></FaSpinner>
          ),
        })}
      </form>
    </div>
  );
};

export default EmailInput;
