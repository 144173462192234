import React, { createContext, useContext, useEffect, useState } from 'react';
import { CTA_QUERY_PARAM, HIDE_NAV_QUERY_PARAM } from '../constants';

// 'default' same as not passing param
const CTA_PARAM_VALID_VALUES = ['default', 'none'];
// 'off' same as not passing param, 'default' removes nav from header and footer
const NO_NAV_PARAM_VALID_VALUES = ['default', 'all', 'off'];

interface URLParamsContextValue {
  ctaParam: string | null;
  noNavParam: string | null;
}

// We assume this URLParamsContext will never be used unless inside
// the URLParamsContext.Provider, and so the default is never used.
const URLParamsContext = createContext<URLParamsContextValue>({} as URLParamsContextValue);

export const useURLParamsContext = () => useContext(URLParamsContext);

interface URLParamsContextProviderProps {
  children: React.ReactNode;
}

export function URLParamsContextProvider({ children }: URLParamsContextProviderProps) {
  const [ctaParam, setCtaParam] = useState<string | null>(null);
  const [noNavParam, setNoNavParam] = useState<string | null>(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const ctaParamValue = urlSearchParams.get(CTA_QUERY_PARAM);
    const noNavParamValue = urlSearchParams.get(HIDE_NAV_QUERY_PARAM);

    if (
      ctaParamValue !== null &&
      (ctaParamValue === '' || CTA_PARAM_VALID_VALUES.includes(ctaParamValue))
    ) {
      setCtaParam(ctaParamValue || 'default');
      sessionStorage.setItem(CTA_QUERY_PARAM, ctaParamValue || 'default');
    } else {
      const storedCtaParam = sessionStorage.getItem(CTA_QUERY_PARAM);
      if (storedCtaParam) {
        setCtaParam(storedCtaParam);
      }
    }

    if (
      noNavParamValue !== null &&
      (noNavParamValue === '' || NO_NAV_PARAM_VALID_VALUES.includes(noNavParamValue))
    ) {
      setNoNavParam(noNavParamValue || 'default');
      sessionStorage.setItem(HIDE_NAV_QUERY_PARAM, noNavParamValue || 'default');
    } else {
      const storedNoNavParam = sessionStorage.getItem(HIDE_NAV_QUERY_PARAM);
      if (storedNoNavParam) {
        setNoNavParam(storedNoNavParam);
      }
    }
  }, []);

  const value: URLParamsContextValue = {
    ctaParam,
    noNavParam,
  };

  return <URLParamsContext.Provider value={value}>{children}</URLParamsContext.Provider>;
}
