import BlockContent from '@sanity/block-content-to-react';
import React, { useState } from 'react';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import serializers from '../../../serializers';
import { RawPortableText } from '../../../types/types';
import { useWindowDimensions } from '../../../utils/hooks';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import Accordion from '../Accordion';
import ButtonLink from '../ButtonLink';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './AccordionModule.module.scss';

export interface AccordionModuleProps {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  text?: string;
  accordions: Array<{
    title: string;
    _rawText: RawPortableText;
    image: SanityImageType;
  }>;
  primaryButton?: ButtonLinkType;
  secondaryButton?: ButtonLinkType;
  className?: string;
}

export function getModuleBgColor(props: AccordionModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function AccordionModule(props: AccordionModuleProps & CommonModuleProps): React.ReactElement {
  const {
    backgroundColor,
    title,
    text,
    accordions,
    primaryButton,
    secondaryButton,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  const windowDimensions = useWindowDimensions();

  const isDesktop = windowDimensions.width && windowDimensions.width >= 1200;

  const [activeAccordionIndex, setActiveAccordionIndex] = useState<number | null>(
    isDesktop ? 0 : null,
  );

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      title={title}
      text={text}
      childrenClassName={styles.accordionsContainer}
    >
      <div className={styles.accordionsWrapper}>
        {accordions.map((accordion, i) => (
          <Accordion
            key={i}
            title={(i + 1).toString().padStart(2, '0') + '. ' + accordion.title}
            dontCloseOnDesktop
            onClose={() => {
              if (isDesktop) {
                setActiveAccordionIndex(0);
              } else {
                setActiveAccordionIndex(null);
              }
            }}
            onOpen={() => setActiveAccordionIndex(i)}
            isAccordionOpen={activeAccordionIndex === i}
          >
            <BlockContent
              renderContainerOnSingleChild
              blocks={accordion._rawText}
              serializers={serializers}
              className={styles.text}
            />
            <hr className={styles.divider}></hr>
            <div className={styles.mobileImageContainer}>
              <Image image={accordion.image} className={styles.image} />
            </div>
          </Accordion>
        ))}
      </div>
      <div className={styles.buttonAndImagesContainer}>
        {accordions.map((accordion, i) => (
          <div className={styles.desktopImageContainer} key={i}>
            <Image
              image={accordion.image}
              className={clsx(styles.image, activeAccordionIndex === i && styles.imageVisible)}
            />
          </div>
        ))}
        {(primaryButton || secondaryButton) && (
          <div className={styles.buttonsContainer}>
            {primaryButton && (
              <ButtonLink
                to={primaryButton}
                linkClassName={styles.buttonContainer}
                className={styles.button}
              >
                {primaryButton.title}
              </ButtonLink>
            )}
            {secondaryButton && (
              <ButtonLink
                to={secondaryButton}
                linkClassName={styles.buttonContainer}
                className={styles.button}
                outlined
              >
                {secondaryButton.title}
              </ButtonLink>
            )}
          </div>
        )}
      </div>
    </ModuleLayout>
  );
}

export default AccordionModule;
