import React from 'react';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ModuleLayout from '../ModuleLayout';
import * as styles from './QuoteModule.module.scss';

export interface QuoteModuleProps {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  quote: string;
  author: string;
  position: string;
  className?: string;
}

export function getModuleBgColor(props: QuoteModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function QuoteModule(props: QuoteModuleProps & CommonModuleProps): React.ReactElement {
  const {
    backgroundColor,
    title,
    quote,
    author,
    position,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      title={title}
      childrenClassName={styles.contentContainer}
    >
      <blockquote className={styles.quote}>"{quote}"</blockquote>
      <div className={styles.authorContainer}>
        <div className={styles.divider}></div>
        <div className={styles.authorInfo}>
          <span className={styles.authorName}>
            {author}
            <span className={clsx(styles.authorPosition, styles.desktop)}>{position}</span>
          </span>
          <span className={clsx(styles.authorPosition, styles.mobile)}>{position}</span>
        </div>
      </div>
    </ModuleLayout>
  );
}

export default QuoteModule;
