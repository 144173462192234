import { TrackingData } from './utils/hooks';
import { urlJoin } from './utils/utils';

export async function addContactToKeap({
  formType,
  sanityDocumentId,
  email,
  acceptTheTerms,
  joinTheHookPointNewsletter,
  activationGuideUrl,
  data,
  trackingData,
}: {
  email: string;
  acceptTheTerms?: boolean;
  joinTheHookPointNewsletter?: boolean;
  activationGuideUrl?: string;
  data?: { [key: string]: string };
  trackingData: TrackingData;
} & (
  | {
      formType: 'insight' | 'newsletter';
      sanityDocumentId?: undefined;
    }
  | {
      formType: 'modal';
      sanityDocumentId: string;
    }
)) {
  if (process.env.NODE_ENV === 'development') {
    // Code to test form states (should be commented)
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
  }

  const resp = await fetch(urlJoin('/.netlify/functions/add-contact-to-keap'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      formType,
      sanityDocumentId,
      email,
      acceptTheTerms,
      joinTheHookPointNewsletter,
      activationGuideUrl,
      data,
      utm_source: trackingData.utmSource,
      utm_medium: trackingData.utmMedium,
      utm_campaign: trackingData.utmCampaign,
      utm_term: trackingData.utmTerm,
      utm_content: trackingData.utmContent,
      utm_device: trackingData.utmDevice,
      utm_target: trackingData.utmTarget,
      // Keeping this one just for backward compatibility
      trafficSourceUrl: urlJoin(window.location.origin, window.location.pathname),
      sourceUrl: urlJoin(window.location.origin, window.location.pathname),
      referralSource: trackingData.websiteReferrer,
    }),
  });
  if (resp.status !== 200 && resp.status !== 202) {
    throw new Error(
      `Got response with status code ${resp.status} for add-contact-to-keap with email ${email}`,
    );
  }
  return true;
}
