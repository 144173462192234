import React from 'react';
import { clsx } from '../../utils/utils';

import * as styles from './MenuButton.module.scss';

interface MenuButtonProps {
  isMenuOpen: boolean;
  onTouch: () => void;
  className?: string;
}

const MenuButton = ({ isMenuOpen, onTouch, className }: MenuButtonProps): React.ReactElement => {
  return (
    <button
      className={clsx(styles.menuWrap, isMenuOpen && styles.menuOpen, className)}
      onClick={onTouch}
    >
      <div className={styles.icon}>
        <div className={styles.line}></div>
        <div className={styles.visuallyHidden}>Menu</div>
      </div>
    </button>
  );
};

export default MenuButton;
