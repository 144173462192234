// extracted by mini-css-extract-plugin
export var arrow = "NumbersGridModule-module--arrow--1812e";
export var button = "NumbersGridModule-module--button--6763d";
export var contentContainer = "NumbersGridModule-module--contentContainer--53948";
export var divider = "NumbersGridModule-module--divider--951d8";
export var link = "NumbersGridModule-module--link--bb747";
export var number = "NumbersGridModule-module--number--b19e3";
export var numberContainer = "NumbersGridModule-module--numberContainer--697f7";
export var numberLink = "NumbersGridModule-module--numberLink--d72cf";
export var numberWrapper = "NumbersGridModule-module--numberWrapper--f7949";
export var numbersGridContainer = "NumbersGridModule-module--numbersGridContainer--a9892";
export var numbersGridWrapper = "NumbersGridModule-module--numbersGridWrapper--bb1cf";
export var rowLayout = "NumbersGridModule-module--rowLayout--1a280";
export var subtitle = "NumbersGridModule-module--subtitle--5fd6a";
export var superTitle = "NumbersGridModule-module--superTitle--9f4db";
export var text = "NumbersGridModule-module--text--814b8";
export var withColoredBackground = "NumbersGridModule-module--withColoredBackground--b5f34";