// extracted by mini-css-extract-plugin
export var itemText = "NumberedListModule-module--itemText--c4766";
export var itemTitle = "NumberedListModule-module--itemTitle--e4d29";
export var listContainer = "NumberedListModule-module--listContainer--69884";
export var listItem = "NumberedListModule-module--listItem--065eb";
export var number = "NumberedListModule-module--number--e268b";
export var sectionContainer = "NumberedListModule-module--sectionContainer--226af";
export var text = "NumberedListModule-module--text--82480";
export var textContainer = "NumberedListModule-module--textContainer--fb796";
export var title = "NumberedListModule-module--title--51cf4";
export var titleDivider = "NumberedListModule-module--titleDivider--d8e24";