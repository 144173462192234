import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React from 'react';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { Insight } from '../../../templates/InsightPage';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import InsightCarousel from '../InsightCarousel';
import ModuleLayout from '../ModuleLayout';
import * as styles from './InsightsCarouselModule.module.scss';

export type InsightsCarouselModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  className?: string;
} & (
  | {
      referencesToUse: 'all';
      insightReferences?: never;
    }
  | {
      referencesToUse: 'chooseManually';
      insightReferences: Array<Insight>;
    }
);

interface QueryData {
  allSanityInsight: {
    nodes: Array<Insight>;
  };
}

export function getModuleBgColor(props: InsightsCarouselModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function InsightsCarouselModule(
  props: InsightsCarouselModuleProps & CommonModuleProps,
): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityInsight: allSanityHpWebsiteInsight(sort: { orderRank: ASC }) {
        nodes {
          ...Insight
        }
      }
    }
  `);

  const groqQuery = groq`{
    "allSanityInsight": {
      "nodes": *[_type == "hp-website-insight"] | order(orderRank asc) {
        ...
      }
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const { title, referencesToUse, insightReferences, className, moduleId, previousModuleBgColor } =
    props;

  return (
    <ModuleLayout
      id={moduleId}
      className={clsx(className, styles.container)}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      contentClassName={styles.contentContainer}
      childrenClassName={styles.content}
      title={title}
    >
      <InsightCarousel
        insights={
          referencesToUse === 'chooseManually' ? insightReferences : data.allSanityInsight.nodes
        }
      />
    </ModuleLayout>
  );
}

export default InsightsCarouselModule;
