import BlockContent from '@sanity/block-content-to-react';
import { motion, useScroll, useSpring } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { IoMdPause, IoMdPlay } from 'react-icons/io';
import { ModuleBackgroundColor } from '../components/ModulesContent';
import PageSEO from '../components/PageSEO';
import ButtonLink from '../components/ui/ButtonLink';
import CategoryTag from '../components/ui/CategoryTag';
import Form, { FormField, FormFieldWithId, FormType } from '../components/ui/Form';
import Image from '../components/ui/Image';
import SanityLayout from '../components/ui/SanityLayout';
import SocialMediaShare from '../components/ui/SocialMediaShare';
import TermsAndConditionsModal from '../components/ui/TermsAndConditionsModal';
import TextLink from '../components/ui/TextLink';
import ArticlesListingModule from '../components/ui/modules/ArticlesListingModule';
import CTAModule from '../components/ui/modules/CTAModule';
import { useURLParamsContext } from '../contexts/URLParamsContext';
import serializers from '../serializers';
import { RawPortableText } from '../types/types';
import { useScrollY } from '../utils/hooks';
import { uniqBy } from '../utils/nodash';
import { getPortableText, getPortableTextAsString } from '../utils/sanity';
import { clsx, getEstimatedReadingTimeInMinutes, slugify } from '../utils/utils';
import { Article } from './ArticlePage';
import * as styles from './ArticleTemplatePage.module.scss';
import { Insight } from './InsightPage';

type ArticleTemplatePageProps = (Article | Insight) & {
  otherArticles?: Array<Article | Insight>;
  articleType: 'insight' | 'article';
  form?: FormType;
  activationGuideUrl?: string;
  ctaModuleId?: string;
  textAtTheBottomOfTheArticle?: RawPortableText;
  backgroundColor?: ModuleBackgroundColor;
};

const ArticleTemplatePage = ({
  otherArticles,
  backgroundColor,
  title,
  image,
  excerpt,
  _rawContent,
  date,
  _createdAt,
  category,
  tags,
  audio,
  form,
  activationGuideUrl,
  articleType,
  ctaSection,
  textAtTheBottomOfTheArticle,
  ctaModuleId,
  seo,
}: ArticleTemplatePageProps): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const additionalFormFields = [
    {
      crmFieldId: 'acceptTheTerms',
      fieldType: 'singleCheckbox',
      isFieldRequired: true,
      textNode: (
        <p>
          I agree to the{' '}
          <TextLink
            styleOnly
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            terms and conditions
          </TextLink>{' '}
        </p>
      ),
      rawId: 'I agree to the terms and conditions',
    },
    {
      crmFieldId: 'joinTheHookPointNewsletter',
      fieldType: 'singleCheckbox',
      isFieldRequired: false,
      _rawText: getPortableText(['Join The Going Viral Newsletter!']),
    },
  ] as Array<FormField>;

  const formFieldsWithIds: Array<FormFieldWithId> = form
    ? [...form.form.fields, ...additionalFormFields].map(formField => ({
        id: slugify(
          formField.fieldType === 'singleCheckbox'
            ? formField.rawId || getPortableTextAsString(formField._rawText as RawPortableText)
            : formField.title,
        ),
        ...formField,
      }))
    : [];

  if (formFieldsWithIds!.length !== uniqBy(formFieldsWithIds!, 'id').length) {
    throw new Error(
      'Got duplicate ids in formFieldsWithIds: ' +
        JSON.stringify(formFieldsWithIds!.map(formField => formField.id)),
    );
  }

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const articleRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: articleRef,
    offset: ['start 50%', 'end 50%'],
  });
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  const [formRef, setFormRef] = useState<HTMLDivElement | null>(null);

  const [isScrollOnOrPastForm, setIsScrollOnOrPastForm] = useState(false);

  const scrollY = useScrollY();
  useEffect(() => {
    if (scrollY && formRef) {
      setIsScrollOnOrPastForm(formRef.getBoundingClientRect().top < 200);
    }
  }, [formRef, scrollY]);

  const { ctaParam, noNavParam } = useURLParamsContext();
  const showOtherArticles = noNavParam !== 'all';

  return (
    <SanityLayout pageTheme={backgroundColor ? backgroundColor : 'light'}>
      <PageSEO
        defaultTitle={title}
        defaultDescription={excerpt}
        defaultImageUrl={image?.asset.url}
        pageSEO={seo}
      />
      <div className={styles.articlePageContainer}>
        <motion.div className={styles.progressBar} style={{ scaleX }} />
        {form?.floatingButtonText && (
          <ButtonLink
            className={clsx(
              styles.floatingButtonForm,
              isScrollOnOrPastForm && styles.floatingButtonHidden,
            )}
            to={{ anchorLink: '#insight-form' }}
            withoutIconAnimation
          >
            {form.floatingButtonText}
          </ButtonLink>
        )}
        <div className={styles.hero}>
          <div className={styles.heroContainer}>
            {category && (
              <div className={styles.categoryContainer}>
                <CategoryTag categoryTitle={category.title} />
              </div>
            )}
            <div className={styles.imageContainer}>
              {image && <Image image={image} width={2800} height={1280} cover />}
            </div>
            <div className={styles.overlay}></div>
            <div className={styles.textContainer}>
              <div className={styles.textWrapper}>
                <h1 className={styles.title}>{title}</h1>
                {/* {excerpt && <p className={styles.description}>{excerpt}</p>} */}
              </div>
              <div className={styles.infoContainer}>
                <p className={styles.info}>
                  {new Date(date || _createdAt).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </p>
                <p className={styles.info}>
                  {getEstimatedReadingTimeInMinutes(_rawContent)} min read
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.articleContainer}>
          <div className={styles.articleWrapper}>
            <div className={styles.leftColumnContainer}>
              {audio && audio.asset.extension === 'mp3' && (
                <div className={styles.audioContainer}>
                  {!isAudioPlaying && (
                    <button className={styles.audioWrapper} onClick={() => setIsAudioPlaying(true)}>
                      <div className={styles.audioIconContainer}>
                        <IoMdPlay className={clsx(styles.audioIcon, styles.play)} />
                      </div>
                      Listen to this article
                    </button>
                  )}
                  {isAudioPlaying && (
                    <AudioPlayer
                      src={audio.asset.url}
                      autoPlay
                      showJumpControls={false}
                      customVolumeControls={[]}
                      customAdditionalControls={[]}
                      customIcons={{
                        play: (
                          <div className={styles.audioIconContainer}>
                            <IoMdPlay className={clsx(styles.audioIcon, styles.play)} />
                            <IoMdPause
                              className={clsx(
                                styles.audioIcon,
                                styles.pause,
                                styles.audioIconHidden,
                              )}
                            />
                          </div>
                        ),
                        pause: (
                          <div className={styles.audioIconContainer}>
                            <IoMdPlay
                              className={clsx(
                                styles.audioIcon,
                                styles.play,
                                styles.audioIconHidden,
                              )}
                            />
                            <IoMdPause className={clsx(styles.audioIcon, styles.pause)} />
                          </div>
                        ),
                      }}
                    />
                  )}
                </div>
              )}
              <article ref={articleRef}>
                <BlockContent
                  renderContainerOnSingleChild
                  blocks={_rawContent}
                  serializers={serializers}
                  className={styles.text}
                />
                {!ctaSection?.hideCtaSection && textAtTheBottomOfTheArticle && (
                  <BlockContent
                    renderContainerOnSingleChild
                    blocks={textAtTheBottomOfTheArticle}
                    serializers={serializers}
                    className={styles.connectText}
                  />
                )}
              </article>
            </div>
            <div className={styles.rightColumnContainer}>
              {tags && tags.length > 0 && (
                <div className={styles.tagsContainer}>
                  <h4 className={styles.subtitle}>Tags</h4>
                  <div className={styles.tagsWrapper}>
                    {tags.map((tag, i) => (
                      <div className={styles.tag} key={i}>
                        {tag.title}
                      </div>
                    ))}
                  </div>
                  <div className={styles.tagDivider}></div>
                </div>
              )}
              <div className={clsx(styles.socialMediaContainer, styles.desktop)}>
                <h4 className={styles.subtitle}>Share on Social Media</h4>
                <SocialMediaShare theme={backgroundColor} />
              </div>
              {form?.sidebarButtonText && form.sidebarTitle && (
                <div className={styles.formButtonContainer}>
                  <h4 className={styles.subtitle}>{form.sidebarTitle}</h4>
                  <ButtonLink
                    to={{ anchorLink: '#insight-form' }}
                    withoutIconAnimation
                    className={styles.formButton}
                  >
                    {form.sidebarButtonText}
                  </ButtonLink>
                </div>
              )}
            </div>
          </div>
          <div className={styles.socialMediaDivider}></div>
          <div className={clsx(styles.socialMediaContainer, styles.mobile)}>
            <h4 className={styles.subtitle}>Share on Social Media</h4>
            <SocialMediaShare />
          </div>
        </div>
        {form && formFieldsWithIds.length > 0 && (
          <div className={styles.form} ref={newRef => setFormRef(newRef)}>
            <Form
              formType="insight"
              fields={formFieldsWithIds}
              submitButtonText={form.form.submitButtonText}
              thankYouScreen={form.form.thankYouScreen}
              title={form.title}
              text={form.text}
              activationGuideUrl={activationGuideUrl}
            />
            <TermsAndConditionsModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
          </div>
        )}
        <div className={styles.dividerContainer}>
          {otherArticles && showOtherArticles && <div className={styles.divider}></div>}
        </div>
        {otherArticles && showOtherArticles && (
          <ArticlesListingModule
            backgroundColor={backgroundColor ? backgroundColor : 'light'}
            referencesToUse="chooseManually"
            articleReferences={otherArticles}
            title={`Other ${articleType === 'article' ? 'articles' : 'insights'}`}
            articleType={articleType}
            withPagination
            dontUpdatePageOnUrl
            itemsPerPage={3}
          />
        )}
        {!ctaSection?.hideCtaSection && ctaParam !== 'none' && (
          <CTAModule
            moduleId={ctaModuleId}
            previousModuleBgColor={backgroundColor ? backgroundColor : 'light'}
            title={ctaSection?.title}
            text={
              ctaSection?.text || (ctaSection?.hideTextFieldInsteadOfUsingDefault ? ' ' : undefined)
            }
            button={ctaSection?.button}
          />
        )}
      </div>
    </SanityLayout>
  );
};

export default ArticleTemplatePage;
