import { graphql } from 'gatsby';
import groq from 'groq';
import React from 'react';
import { FormType } from '../components/ui/Form';
import { ButtonLinkType } from '../graphql-fragments/ButtonLink';
import { SanityImageType } from '../graphql-fragments/SanityImage';
import { LocalizedSEO } from '../graphql-fragments/seo';
import { withPagePreview } from '../preview/previewUtils';
import { RawPortableText } from '../types/types';
import ArticleTemplatePage from './ArticleTemplatePage';

export const query = graphql`
  fragment Insight on SanityHpWebsiteInsight {
    _id
    title
    slug {
      current
    }
    date
    _createdAt
    image {
      ...SanityImage
      asset {
        url
      }
    }
    excerpt
    category {
      _id
      title
    }
    tags {
      title
    }
    audio {
      asset {
        extension
        url
      }
    }
    _rawContent(resolveReferences: { maxDepth: 4 })
    activationGuideUrl
    ctaSection {
      title
      text
      hideTextFieldInsteadOfUsingDefault
      hideCtaSection
      button {
        ...ButtonLink
      }
    }
    seo {
      ...SEO
    }
  }

  query GetInsightPageById($id: String!) {
    sanityHpWebsiteInsight(_id: { eq: $id }) {
      ...Insight
    }

    allSanityInsight: allSanityHpWebsiteInsight(sort: { orderRank: ASC }) {
      nodes {
        ...Insight
      }
    }

    sanityHpWebsiteInsightSettings(_id: { eq: "hp-website-insightSettings-singleton" }) {
      formSection {
        title
        text
        form {
          fields {
            fieldType
            displayType
            title
            crmFieldId
            withEmailValidation
            selectOptions {
              title
              crmValueStored
            }
            isFieldRequired
            _rawText(resolveReferences: { maxDepth: 4 })
          }

          submitButtonText
          thankYouScreen {
            title
            _rawText(resolveReferences: { maxDepth: 4 })
            scheduleACallButton {
              ...ButtonLink
            }
          }
        }
        sidebarTitle
        sidebarButtonText
        floatingButtonText
      }
    }
  }
`;

const groqQuery = groq`{
  "sanityHpWebsiteInsight": *[_id == $id][0] {
     ...
  },
  "allSanityInsight": {
    "nodes": *[_type == "hp-website-insight"] | order(orderRank asc) {
      ...
    }
  },
  "sanityHpWebsiteInsightSettings": *[_id == "hp-website-insightSettings-singleton"][0] {
    formSection {
      ...
    }
  }
}`;

export interface Insight {
  _id: string;
  title: string;
  slug: {
    current: string;
  };
  date?: string;
  _createdAt: string;
  image: SanityImageType & {
    asset: {
      url: string;
    };
  };
  excerpt?: string;
  category?: {
    _id: string;
    title: string;
  };
  tags?: Array<{
    title: string;
  }>;
  audio?: {
    asset: {
      extension: string;
      url: string;
    };
  };
  _rawContent: RawPortableText;
  activationGuideUrl: string;
  ctaSection?: {
    title?: string;
    text?: string;
    hideTextFieldInsteadOfUsingDefault?: boolean;
    hideCtaSection?: boolean;
    button?: ButtonLinkType;
  };
  seo?: LocalizedSEO;
}

interface QueryData {
  sanityHpWebsiteInsight: Insight;
  allSanityInsight: {
    nodes: Array<Insight>;
  };
  sanityHpWebsiteInsightSettings: {
    formSection?: FormType;
  };
}

const InsightPage = withPagePreview<QueryData>(
  {
    groqQuery,
  },
  ({
    data: {
      allSanityInsight: { nodes: allInsights },
      sanityHpWebsiteInsight: currentInsight,
      sanityHpWebsiteInsightSettings,
    },
  }): React.ReactElement => {
    const currentInsightId = currentInsight._id;

    const allInsightExceptCurrent = allInsights.filter(
      insight => !currentInsightId.includes(insight._id),
    );

    const insightsWithSameCategory = allInsightExceptCurrent.filter(
      insight => insight.category?._id === currentInsight.category?._id,
    );
    const otherInsights = allInsightExceptCurrent.filter(
      insight => insight.category?._id !== currentInsight.category?._id,
    );

    const insightsToShow = [...insightsWithSameCategory, ...otherInsights];

    return (
      <ArticleTemplatePage
        {...currentInsight}
        otherArticles={insightsToShow}
        articleType="insight"
        backgroundColor={'dark'}
        form={sanityHpWebsiteInsightSettings?.formSection}
        activationGuideUrl={currentInsight.activationGuideUrl}
      />
    );
  },
);

export default InsightPage;
