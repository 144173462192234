import BlockContent from '@sanity/block-content-to-react';
import React, { useState } from 'react';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import serializers from '../../../serializers';
import { RawPortableText } from '../../../types/types';
import { getPortableTextAsString } from '../../../utils/sanity';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import Button from '../Button';
import ButtonLink from '../ButtonLink';
import Image from '../Image';
import ModalOverlay from '../ModalOverlay';
import ModuleLayout from '../ModuleLayout';
import RatingStars from '../RatingStars';
import Video from '../Video';
import * as styles from './TextAndMediaModule.module.scss';

export interface TextAndMediaModuleProps {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  subtitle?: string;
  mediaType: 'image' | 'video';
  image?: SanityImageType;
  imageHasTransparentBackground?: boolean;
  imageSubtitle?: string;
  video?: string;
  _rawText: RawPortableText;
  _rawModalContent?: RawPortableText;
  modalContentButtonLabel?: string;
  withHighlightedBackground?: boolean;
  moduleAligment: 'textLeftImageRight' | 'imageLeftTextRight';
  button?: ButtonLinkType;
  rating?: 1 | 2 | 3 | 4 | 5;
  ratingDescription?: string;
  className?: string;
}

export function getModuleBgColor(props: TextAndMediaModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function TextAndMediaModule(
  props: TextAndMediaModuleProps & CommonModuleProps,
): React.ReactElement {
  const {
    title,
    _rawText,
    _rawModalContent,
    modalContentButtonLabel,
    mediaType,
    image,
    imageHasTransparentBackground,
    imageSubtitle,
    video,
    button,
    moduleAligment,
    withHighlightedBackground,
    subtitle,
    rating,
    ratingDescription,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  const [isModalOpen, setItModalOpen] = useState(false);

  return (
    <ModuleLayout
      id={moduleId}
      className={clsx(
        styles.content,
        withHighlightedBackground && styles.withHighlightedBackground,
        withHighlightedBackground &&
          previousModuleBgColor !== getModuleBgColor(props) &&
          styles.noInternalTopPadding,
        imageHasTransparentBackground && styles.imageWithTransparentBackground,
        className,
      )}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      contentClassName={styles.contentContainer}
    >
      <div
        className={clsx(
          styles.container,
          moduleAligment === 'textLeftImageRight' && styles.textLeftMediaRight,
        )}
      >
        {mediaType === 'video' && video && (
          <div className={clsx(styles.mediaContainer, mediaType === 'video' && styles.video)}>
            <Video url={video} />
          </div>
        )}

        {mediaType === 'image' && image && (
          <div className={clsx(styles.mediaContainer, image && styles.image)}>
            <Image image={image} />
            {imageSubtitle && (
              <span className={clsx(styles.imageSubtitle, styles.mobile)}>{imageSubtitle}</span>
            )}
          </div>
        )}

        <div className={clsx(styles.textBlockContainer, modalContentButtonLabel && styles.lessGap)}>
          <div className={styles.textContentContainer}>
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>{title}</h2>
              {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
              <div className={styles.titleDivider}></div>
            </div>
            <BlockContent
              className={clsx(
                styles.portableContent,
                getPortableTextAsString(_rawText).length > 300 && styles.smallerFont,
              )}
              blocks={_rawText}
              renderContainerOnSingleChild
              serializers={serializers}
            />
            {(rating || ratingDescription) && (
              <div className={styles.ratingAndDescriptionContainer}>
                {rating && (
                  <div className={styles.ratingContainer}>
                    <span className={styles.rating}>{rating}</span>
                    <RatingStars rating={rating} />
                  </div>
                )}
                {rating && ratingDescription && <div className={styles.ratingDivider}></div>}
                {ratingDescription && (
                  <p className={styles.ratingDescription}>{ratingDescription}</p>
                )}
              </div>
            )}
          </div>

          {(button || (modalContentButtonLabel && _rawModalContent) || imageSubtitle) && (
            <div className={styles.buttonAndImageSubtitleContainer}>
              {(button || modalContentButtonLabel) && (
                <div className={styles.buttonsContainer}>
                  {button && (
                    <ButtonLink to={button} className={styles.button}>
                      {button.title}
                    </ButtonLink>
                  )}
                  {modalContentButtonLabel && _rawModalContent && (
                    <>
                      <Button
                        onClick={() => setItModalOpen(true)}
                        className={styles.button}
                        outlined
                      >
                        {modalContentButtonLabel}
                      </Button>
                      <ModalOverlay open={isModalOpen} onClose={() => setItModalOpen(false)}>
                        <div className={styles.modalContentContainer}>
                          <div className={styles.titleContainer}>
                            <h2 className={styles.title}>{title}</h2>
                            {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
                            <div className={styles.titleDivider}></div>
                          </div>
                          <BlockContent
                            className={clsx(styles.portableContent, styles.modalContent)}
                            blocks={_rawModalContent}
                            renderContainerOnSingleChild
                            serializers={serializers}
                          />
                        </div>
                      </ModalOverlay>
                    </>
                  )}
                </div>
              )}
              {imageSubtitle && (
                <span className={clsx(styles.imageSubtitle, styles.tablet)}>{imageSubtitle}</span>
              )}
            </div>
          )}
          {imageSubtitle && (
            <span className={clsx(styles.imageSubtitle, styles.desktop)}>{imageSubtitle}</span>
          )}
        </div>
      </div>
    </ModuleLayout>
  );
}

export default TextAndMediaModule;
