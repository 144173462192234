import React from 'react';

import { BsArrowRightShort } from 'react-icons/bs';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import { VersatileLink } from '../../types/types';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import { clsx } from '../../utils/utils';
import * as styles from './Card.module.scss';
import CategoryTag from './CategoryTag';
import Image from './Image';
import SmartLink from './SmartLink';

export interface CardType {
  image: SanityImageType;
  title: string;
  text?: string;
  category?: JSX.Element;
  link: VersatileLink;
  linkText?: string;
  linkIcon?: 'arrow' | 'externalLink';
}

export type CardProps = CardType & {
  withTallerImage?: boolean;
  hideImageOnMobile?: boolean;
  className?: string;
};

const Card = ({
  image,
  title,
  text,
  link,
  category,
  withTallerImage,
  hideImageOnMobile,
  linkText,
  linkIcon,
  className,
}: CardProps): React.ReactElement => {
  return (
    <SmartLink
      className={clsx(
        styles.container,
        className,
        withTallerImage && styles.withTallerImage,
        hideImageOnMobile && styles.hideImageOnMobile,
      )}
      to={getUrlFromVersatileLink(link)}
    >
      {category && <CategoryTag categoryTitle={category} className={styles.category} />}
      <div className={styles.imageContainer}>
        {/* Validating image just so it doesn't crash without it */}
        {image && (
          <Image
            image={image}
            alt={'Illustration of ' + title}
            className={styles.image}
            width={440}
            height={withTallerImage ? 340 : 210}
            cover
          />
        )}
      </div>
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{title}</h3>
        {text && <p className={styles.text}>{text}</p>}
        <div className={clsx(styles.link, !text && styles.withMarginTop)}>
          {linkText || 'View post'}
          {linkIcon === 'externalLink' ? (
            <FaExternalLinkAlt
              className={styles.externalLink}
              aria-label="go-external-link"
              role="img"
            />
          ) : (
            <BsArrowRightShort className={styles.arrow} aria-label="go-arrow" role="img" />
          )}
        </div>
      </div>
    </SmartLink>
  );
};

export default Card;
