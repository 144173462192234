// extracted by mini-css-extract-plugin
export var contentContainer = "CloserModule-module--contentContainer--a8dd5";
export var divider = "CloserModule-module--divider--261b9";
export var imageContainer = "CloserModule-module--imageContainer--b043e";
export var resultContainer = "CloserModule-module--resultContainer--37f46";
export var resultNumber = "CloserModule-module--resultNumber--31f49";
export var resultSubtitle = "CloserModule-module--resultSubtitle--fe705";
export var resultsContainer = "CloserModule-module--resultsContainer--47745";
export var testimonial = "CloserModule-module--testimonial--f699c";
export var testimonialAuthor = "CloserModule-module--testimonialAuthor--3214c";
export var testimonialAuthorContainer = "CloserModule-module--testimonialAuthorContainer--dcbc5";
export var testimonialAuthorPosition = "CloserModule-module--testimonialAuthorPosition--fc189";
export var testimonialTitle = "CloserModule-module--testimonialTitle--050b1";
export var text = "CloserModule-module--text--defa0";
export var textContainer = "CloserModule-module--textContainer--8a083";
export var title = "CloserModule-module--title--7e43d";
export var titleDivider = "CloserModule-module--titleDivider--144ff";