// extracted by mini-css-extract-plugin
export var button = "TextAndMediaModule-module--button--aa690";
export var buttonAndImageSubtitleContainer = "TextAndMediaModule-module--buttonAndImageSubtitleContainer--e5c2a";
export var buttonsContainer = "TextAndMediaModule-module--buttonsContainer--c27a7";
export var container = "TextAndMediaModule-module--container--d3897";
export var content = "TextAndMediaModule-module--content--3846d";
export var contentContainer = "TextAndMediaModule-module--contentContainer--3347a";
export var desktop = "TextAndMediaModule-module--desktop--f5202";
export var image = "TextAndMediaModule-module--image--563a6";
export var imageSubtitle = "TextAndMediaModule-module--imageSubtitle--56363";
export var imageWithTransparentBackground = "TextAndMediaModule-module--imageWithTransparentBackground--6bc2d";
export var lessGap = "TextAndMediaModule-module--lessGap--0aff0";
export var media = "TextAndMediaModule-module--media--4d4f9";
export var mediaContainer = "TextAndMediaModule-module--mediaContainer--a5026";
export var mobile = "TextAndMediaModule-module--mobile--2f878";
export var modalContent = "TextAndMediaModule-module--modalContent--34576";
export var modalContentContainer = "TextAndMediaModule-module--modalContentContainer--e1427";
export var noInternalTopPadding = "TextAndMediaModule-module--noInternalTopPadding--06764";
export var portableContent = "TextAndMediaModule-module--portableContent--edb1c";
export var rating = "TextAndMediaModule-module--rating--00e3d";
export var ratingAndDescriptionContainer = "TextAndMediaModule-module--ratingAndDescriptionContainer--4ea3b";
export var ratingContainer = "TextAndMediaModule-module--ratingContainer--96a46";
export var ratingDescription = "TextAndMediaModule-module--ratingDescription--6764f";
export var ratingDivider = "TextAndMediaModule-module--ratingDivider--4cccd";
export var smallerFont = "TextAndMediaModule-module--smallerFont--1f841";
export var subtitle = "TextAndMediaModule-module--subtitle--40ce9";
export var tablet = "TextAndMediaModule-module--tablet--412f7";
export var textBlockContainer = "TextAndMediaModule-module--textBlockContainer--cbad9";
export var textContentContainer = "TextAndMediaModule-module--textContentContainer--61b72";
export var textLeftMediaRight = "TextAndMediaModule-module--textLeftMediaRight--e084b";
export var title = "TextAndMediaModule-module--title--e39fe";
export var titleDivider = "TextAndMediaModule-module--titleDivider--b4a74";
export var video = "TextAndMediaModule-module--video--f6123";
export var withHighlightedBackground = "TextAndMediaModule-module--withHighlightedBackground--633dc";