import 'react-multi-carousel/lib/styles.css';

import React from 'react';
import Carousel from 'react-multi-carousel';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import { clsx } from '../../utils/utils';
import Image from './Image';
import * as styles from './TestimonialCarousel.module.scss';

interface TestimonialCarouselProps {
  items: Array<{
    quote: string;
    author: string;
    position: string;
    image: SanityImageType;
  }>;
}

const responsive = {
  everywhere: {
    breakpoint: { max: 3000, min: 0 },
    items: 1,
  },
};

const ButtonGroup = ({
  next,
  previous,
  carouselState: { currentSlide, totalItems, slidesToShow },
}: {
  next: () => void;
  previous: () => void;
  carouselState: { currentSlide: number; totalItems: number; slidesToShow: number };
}) => {
  return (
    <div className={clsx(styles.buttonGroup, totalItems < 2 && styles.noArrows)}>
      <button
        disabled={currentSlide === 0}
        className={styles.button + ' ' + styles.buttonLeft}
        onClick={() => previous()}
      >
        <BsArrowLeft className={styles.buttonIcon} aria-label="left-arrow" role="img" />
        <div className={styles.visuallyHidden}>Previous</div>
      </button>

      <button
        disabled={currentSlide === totalItems - slidesToShow}
        className={styles.button + ' ' + styles.buttonRight}
        onClick={() => next()}
      >
        <BsArrowRight className={styles.buttonIcon} aria-label="right-arrow" role="img" />
        <div className={styles.visuallyHidden}>Next</div>
      </button>
    </div>
  );
};

const TestimonialCarousel = ({ items }: TestimonialCarouselProps): React.ReactElement => {
  return (
    <Carousel
      arrows={false}
      draggable={true}
      responsive={responsive}
      ssr={true}
      containerClass={clsx(styles.carousel, items.length < 2 && styles.noArrows)}
      // @ts-ignore
      customButtonGroup={<ButtonGroup carouselState={{ totalItems: items.length }} />}
      renderButtonGroupOutside
      partialVisible
      showDots={true}
      shouldResetAutoplay={false}
      pauseOnHover
    >
      {items.map((item, i) => (
        <div className={styles.card} key={i}>
          <div className={clsx(styles.imageContainer, styles.desktop)}>
            <Image
              image={item.image}
              alt={'Profile image of ' + item.author}
              className={styles.image}
            />
          </div>
          <div className={styles.item}>
            <blockquote className={styles.text}>"{item.quote}"</blockquote>
            <div className={styles.authorContainer}>
              <div className={clsx(styles.imageContainer, styles.mobile)}>
                <Image
                  alt={'Profile image of ' + item.author}
                  image={item.image}
                  className={styles.image}
                />
              </div>
              <div className={styles.divider}></div>
              <div className={styles.authorInfo}>
                <span className={styles.authorName}>
                  {item.author}
                  <span className={clsx(styles.authorPosition, styles.desktop)}>
                    {item.position}
                  </span>
                </span>
                <span className={clsx(styles.authorPosition, styles.mobile)}>{item.position}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default TestimonialCarousel;
