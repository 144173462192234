// extracted by mini-css-extract-plugin
export var arrow = "FeaturedCaseStudiesModule-module--arrow--842d9";
export var backgroundImage = "FeaturedCaseStudiesModule-module--backgroundImage--79d2a";
export var button = "FeaturedCaseStudiesModule-module--button--031c5";
export var caseStudiesContainer = "FeaturedCaseStudiesModule-module--caseStudiesContainer--b9aa8";
export var caseStudyContainer = "FeaturedCaseStudiesModule-module--caseStudyContainer--cc869";
export var caseStudySpan = "FeaturedCaseStudiesModule-module--caseStudySpan--5c807";
export var category = "FeaturedCaseStudiesModule-module--category--e3789";
export var firstColumnContainer = "FeaturedCaseStudiesModule-module--firstColumnContainer--450f1";
export var gridContainer = "FeaturedCaseStudiesModule-module--gridContainer--754b7";
export var gridLayout = "FeaturedCaseStudiesModule-module--gridLayout--344af";
export var line = "FeaturedCaseStudiesModule-module--line--e4faa";
export var link = "FeaturedCaseStudiesModule-module--link--019c6";
export var listContainer = "FeaturedCaseStudiesModule-module--listContainer--98ce3";
export var name = "FeaturedCaseStudiesModule-module--name--3b4ae";
export var nameDivider = "FeaturedCaseStudiesModule-module--nameDivider--eda0b";
export var overlay = "FeaturedCaseStudiesModule-module--overlay--f5649";
export var results = "FeaturedCaseStudiesModule-module--results--0ecee";
export var secondColumnContainer = "FeaturedCaseStudiesModule-module--secondColumnContainer--31193";
export var shorterHeight = "FeaturedCaseStudiesModule-module--shorterHeight--261e3";
export var textContainer = "FeaturedCaseStudiesModule-module--textContainer--d54f6";