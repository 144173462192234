import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React from 'react';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ButtonLink from '../ButtonLink';
import ModuleLayout from '../ModuleLayout';
import * as styles from './NumbersGridModule.module.scss';

interface NumberType {
  superTitle?: string;
  number: string;
  numberUnit: string;
  subtitle: string;
  description: string;
}

type NumberGridLayout = 'numbersGridUnderText' | 'numbersGridNextToText';

export type NumbersGridModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  button?: ButtonLinkType;
  className?: string;
  title: string;
  text: string;
  layout: NumberGridLayout;
} & (
  | {
      companyResultsToUse: 'firstFour';
      numbers?: never;
    }
  | {
      companyResultsToUse: 'chooseManually';
      numbers: Array<NumberType>;
    }
);

interface QueryData {
  allSanityCompanyResult: {
    nodes: Array<NumberType>;
  };
}

export function getModuleBgColor(props: NumbersGridModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function NumbersGridModule(props: NumbersGridModuleProps & CommonModuleProps): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityCompanyResult(sort: { orderRank: ASC }) {
        nodes {
          superTitle
          number
          numberUnit
          subtitle
          description
        }
      }
    }
  `);

  const groqQuery = groq`{
  "allSanityCompanyResult": {
    "nodes": *[_type == "companyResult"] | order(orderRank asc) {
      ...
    }
  }
}`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const {
    title,
    text,
    layout,
    companyResultsToUse,
    numbers,
    button,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  function renderNumberContainer(number: NumberType, layout: NumberGridLayout) {
    return (
      <div className={clsx(styles.numberContainer)}>
        {layout === 'numbersGridUnderText' && number.superTitle && (
          <span className={styles.superTitle}>{number.superTitle}</span>
        )}
        {layout === 'numbersGridUnderText' &&
          number.superTitle &&
          layout === 'numbersGridUnderText' && <div className={styles.divider}></div>}
        <div className={styles.numberWrapper}>
          <span className={styles.number}>{number.number}</span>
          <span className={styles.subtitle}>{number.numberUnit}</span>
        </div>
        <p className={styles.subtitle}>{number.subtitle}</p>
        {layout === 'numbersGridNextToText' && <p className={styles.text}>{number.description}</p>}
      </div>
    );
  }

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      title={title}
      text={text}
      contentClassName={clsx(
        styles.contentContainer,
        layout === 'numbersGridNextToText' && styles.rowLayout,
      )}
      childrenClassName={styles.numbersGridWrapper}
    >
      <div className={styles.numbersGridContainer}>
        {(companyResultsToUse === 'chooseManually'
          ? numbers
          : data.allSanityCompanyResult.nodes
        ).map((number, i) => (
          <React.Fragment key={i}>{renderNumberContainer(number, layout)}</React.Fragment>
        ))}
      </div>
      {button && layout === 'numbersGridUnderText' && (
        <ButtonLink to={button} linkClassName={styles.button}>
          {button.title}
        </ButtonLink>
      )}
    </ModuleLayout>
  );
}

export default NumbersGridModule;
