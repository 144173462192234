import React, { useState } from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import { capitalize } from '../../../utils/nodash';
import { formatDate } from '../../../utils/projectUtils';
import { truncateText } from '../../../utils/utils';
import Image from '../Image';
import RatingStars from '../RatingStars';
import * as styles from './ReviewCard.module.scss';

const REVIEW_SIZE_LIMIT = 150;

export interface Review {
  author: string;
  image?: SanityImageType;
  review: string;
  rating: 1 | 2 | 3 | 4 | 5;
  source: {
    title: string;
    logo: SanityImageType;
  };
  category: 'agency' | 'books';
  date: string;
}

function ReviewCard({ author, image, review, rating, source, category, date }: Review) {
  const [isExpanded, setIsExpanded] = useState(false);

  function getInitials(fullName: string) {
    const firstName = fullName.trim().split(' ')[0];
    const initial = firstName.charAt(0).toUpperCase();
    return initial;
  }

  return (
    <div className={styles.container}>
      <div className={styles.topInfoContainer}>
        <RatingStars rating={rating} className={styles.rating} />
        <span className={styles.category}>{capitalize(category)}</span>
      </div>
      <div className={styles.authorContainer}>
        <div className={styles.authorAvatar}>
          {image ? (
            <Image image={image} className={styles.authorImage} />
          ) : (
            <div className={styles.noAuthorImageContainer}>
              <span className={styles.authorInitials}>{getInitials(author)}</span>
            </div>
          )}
        </div>
        <p className={styles.authorName}>{author}</p>
      </div>
      {review.length <= REVIEW_SIZE_LIMIT ? (
        <p className={styles.review}>{review}</p>
      ) : (
        <p className={styles.review}>
          {isExpanded ? review : truncateText(review, REVIEW_SIZE_LIMIT)}
          <span
            className={styles.readMoreLink}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? 'Read less' : 'Read more'}
          </span>
        </p>
      )}
      <div className={styles.bottomInfoContainer}>
        <span className={styles.date}>{formatDate(date)}</span>
        <Image image={source.logo} className={styles.sourceImage} />
      </div>
    </div>
  );
}

export default ReviewCard;
