import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { NavigationMenu } from '../../graphql-fragments/navigationMenu';

import groq from 'groq';
import { LocationContext } from '../../contexts/LocationContext';
import { useURLParamsContext } from '../../contexts/URLParamsContext';
import Logo from '../../images/logo.svg';
import whiteLogo from '../../images/logoWhite.svg';
import PreviewLoadingScreen from '../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../preview/previewUtils';
import { ModuleBackgroundColor } from '../ModulesContent';
import Layout from './Layout';

interface SanityLayoutProps {
  children: React.ReactNode;
  pageTheme: ModuleBackgroundColor;
}

interface QueryData {
  sanityHpWebsiteSettings: {
    header: {
      navigationMenu: NavigationMenu;
      ctaButton: ButtonLinkType;
      ctaButtonAnchor?: string;
    };
    footer: {
      navigationLinks: Array<ButtonLinkType>;
      legalLinks: Array<ButtonLinkType>;
    };
  };
}

const SanityLayout = ({ pageTheme, children }: SanityLayoutProps): React.ReactElement => {
  const staticData: QueryData = useStaticQuery(
    graphql`
      {
        sanityHpWebsiteSettings {
          header {
            navigationMenu {
              ...NavigationMenu
            }
            ctaButton {
              ...ButtonLink
            }
            ctaButtonAnchor
          }
          footer {
            navigationLinks {
              ...ButtonLink
            }
            legalLinks {
              ...ButtonLink
            }
          }
        }
      }
    `,
  );

  const groqQuery = groq`{
    "sanityHpWebsiteSettings": *[_id == "hp-website-settings-singleton"][0] {
      header {
        ...
      },
      footer {
        ...
      }
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  const { ctaParam } = useURLParamsContext();

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const { navigationMenu, ctaButton, ctaButtonAnchor } = data.sanityHpWebsiteSettings.header;
  const { navigationLinks, legalLinks } = data.sanityHpWebsiteSettings.footer;

  const location = useContext(LocationContext);

  return (
    <Layout
      pageTheme={pageTheme}
      headerConfig={{
        sticky: true,
        logoImageUrl: Logo,
        entries: navigationMenu,
        ctaButton: ctaParam !== 'none' ? ctaButton : undefined,
        ctaButtonAnchor: ctaButtonAnchor,
        currentUrl: location?.pathname,
      }}
      footerConfig={{
        whiteLogoImageUrl: whiteLogo,
        navigationLinks: navigationLinks,
        legalLinks: legalLinks,
        newsletterBlock: {
          title: 'Subscribe to our newsletter',
        },
      }}
    >
      {children}
    </Layout>
  );
};

export default SanityLayout;
