// extracted by mini-css-extract-plugin
export var caseStudyContainer = "CaseStudyBlock-module--caseStudyContainer--ee4b7";
export var caseStudySubTitle = "CaseStudyBlock-module--caseStudySubTitle--6040c";
export var caseStudyTitle = "CaseStudyBlock-module--caseStudyTitle--d61d5";
export var content = "CaseStudyBlock-module--content--e07e4";
export var image = "CaseStudyBlock-module--image--37670";
export var line = "CaseStudyBlock-module--line--adb4e";
export var resultContainer = "CaseStudyBlock-module--resultContainer--83c0b";
export var resultText = "CaseStudyBlock-module--resultText--29659";
export var resultTitle = "CaseStudyBlock-module--resultTitle--5a50b";
export var resultsContainer = "CaseStudyBlock-module--resultsContainer--002e6";
export var resultsWrapper = "CaseStudyBlock-module--resultsWrapper--d9aa3";
export var text = "CaseStudyBlock-module--text--40447";
export var topic = "CaseStudyBlock-module--topic--eea0c";