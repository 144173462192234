// extracted by mini-css-extract-plugin
export var bookContainer = "BooksModule-module--bookContainer--6845d";
export var bookDescription = "BooksModule-module--bookDescription--39973";
export var bookDesktopImage = "BooksModule-module--bookDesktopImage--4bd6a";
export var bookMobileImage = "BooksModule-module--bookMobileImage--9e92e";
export var bookTitle = "BooksModule-module--bookTitle--2865f";
export var booksContainer = "BooksModule-module--booksContainer--ba3cc";
export var booksWrapper = "BooksModule-module--booksWrapper--b2a24";
export var button = "BooksModule-module--button--d19ca";
export var buttonContainer = "BooksModule-module--buttonContainer--07894";
export var buttonsContainer = "BooksModule-module--buttonsContainer--b29e7";
export var divider = "BooksModule-module--divider--9fc71";
export var firstBookContainer = "BooksModule-module--firstBookContainer--ead55";
export var fullWidth = "BooksModule-module--fullWidth--3ec90";
export var icon = "BooksModule-module--icon--92d94";
export var imageContainer = "BooksModule-module--imageContainer--16864";
export var otherBooksContainer = "BooksModule-module--otherBooksContainer--9f363";
export var otherBooksTitle = "BooksModule-module--otherBooksTitle--a56b9";
export var rowBooks = "BooksModule-module--rowBooks--e2fbf";
export var rowLayout = "BooksModule-module--rowLayout--70a39";
export var textContainer = "BooksModule-module--textContainer--c9a02";