// extracted by mini-css-extract-plugin
export var container = "NestedMenuEntry-module--container--489ca";
export var desktopSubentry = "NestedMenuEntry-module--desktopSubentry--fcbee";
export var divider = "NestedMenuEntry-module--divider--3e980";
export var entryOpen = "NestedMenuEntry-module--entryOpen--6dd09";
export var icon = "NestedMenuEntry-module--icon--d1ac0";
export var menuLink = "NestedMenuEntry-module--menuLink--39d58";
export var nestedItem = "NestedMenuEntry-module--nestedItem--843cb";
export var subMenuDesktop = "NestedMenuEntry-module--subMenuDesktop--670a7";
export var subMenuWrapperDesktop = "NestedMenuEntry-module--subMenuWrapperDesktop--e9d66";
export var subMenuWrapperMobile = "NestedMenuEntry-module--subMenuWrapperMobile--3ffae";