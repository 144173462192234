import BlockContent from '@sanity/block-content-to-react';
import React from 'react';

import { SanityImageType } from '../../graphql-fragments/SanityImage';
import serializers from '../../serializers';
import { RawPortableText } from '../../types/types';
import Image from '../ui/Image';
import * as styles from './CaseStudyBlock.module.scss';

export type CaseStudyBlockProps = {
  caseStudy: {
    _id: string;
    title: string;
    category?: {
      title: string;
    };
    image: SanityImageType;
    resultsNumbers?: Array<{
      title: string;
      _rawText: RawPortableText;
    }>;
    _rawIntroduction: RawPortableText;
    _rawSolution?: RawPortableText;
  };
};

const CaseStudyBlock = ({ caseStudy }: CaseStudyBlockProps): React.ReactElement => {
  return (
    <div className={styles.caseStudyContainer}>
      <div className={styles.content}>
        <span className={styles.topic}>Case Study</span>
        <h4 className={styles.caseStudyTitle}>{caseStudy.title}</h4>
        <BlockContent
          renderContainerOnSingleChild
          className={styles.text}
          blocks={caseStudy._rawIntroduction}
          serializers={serializers}
        />
        <div className={styles.line}></div>
        <span className={styles.caseStudySubTitle}>Solution</span>
        <BlockContent
          renderContainerOnSingleChild
          className={styles.text}
          blocks={caseStudy._rawSolution}
          serializers={serializers}
        />

        {caseStudy.resultsNumbers && caseStudy.resultsNumbers?.length > 0 && (
          <div className={styles.resultsContainer}>
            <span className={styles.caseStudySubTitle}>Results</span>
            <div className={styles.resultsWrapper}>
              {caseStudy.resultsNumbers?.map((resultItem, i) => (
                <span className={styles.resultContainer} key={i}>
                  <span className={styles.resultTitle}>{resultItem.title}</span>
                  <BlockContent
                    renderContainerOnSingleChild
                    className={styles.resultText}
                    blocks={resultItem._rawText}
                    serializers={serializers}
                  />
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={styles.image}>
        <Image
          image={caseStudy.image}
          cover
          dimensions={[
            [1200, 674],
            [1199, 700, 800],
          ]}
          sizes={`(max-width: 1198px) 100vw, 700px`}
        />
      </div>
    </div>
  );
};

export default CaseStudyBlock;
