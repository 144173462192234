import React, { useContext, useEffect, useState } from 'react';

import { AUTHOR_NAME, COPYRIGHT_DEFAULT_YEAR } from '../../constants';
import { LocationContext } from '../../contexts/LocationContext';
import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import { clsx, withDataLayer } from '../../utils/utils';
import EmailInput from './EmailInput';
import * as styles from './Footer.module.scss';
import SmartLink from './SmartLink';
import { useURLParamsContext } from '../../contexts/URLParamsContext';

export type LinkColumn = {
  title: string;
  entries: Array<ButtonLinkType>;
};

type SocialNetworkIconName = 'facebook' | 'linkedin' | 'whatsapp' | 'instagram';
export type SocialNetworksBlock = {
  title?: string;
  socialNetworks?: Array<{
    iconName: SocialNetworkIconName;
    url?: string;
  }>;
};

export type NewsletterBlock = {
  title: string;
};

export interface FooterProps {
  whiteLogoImageUrl: string;
  newsletterBlock?: NewsletterBlock;
  navigationLinks: Array<ButtonLinkType>;
  legalLinks?: Array<ButtonLinkType>;
  hideLinksBlock?: boolean;
  hideNewsletterBlock?: boolean;
}

const Footer = ({
  whiteLogoImageUrl,
  navigationLinks,
  legalLinks,
  newsletterBlock,
  hideLinksBlock,
  hideNewsletterBlock,
}: FooterProps): React.ReactElement => {
  const location = useContext(LocationContext)!;
  const [currentYear, setCurrentYear] = useState<number>(COPYRIGHT_DEFAULT_YEAR);

  const { ctaParam, noNavParam } = useURLParamsContext();

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  function renderLogoBox() {
    return (
      <div className={styles.logoContainer}>
        <img className={styles.companyLogo} src={whiteLogoImageUrl} alt="Hook Point logo" />
      </div>
    );
  }

  function renderCopyright() {
    return (
      <div className={styles.copyright}>
        <span>
          Copyright {currentYear} | All rights reserved to {AUTHOR_NAME}
        </span>
      </div>
    );
  }

  function renderLinks(link: ButtonLinkType) {
    return (
      <SmartLink to={getUrlFromVersatileLink(link)} className={styles.link}>
        {link.title}
      </SmartLink>
    );
  }

  return (
    <footer
      className={clsx(
        styles.footer,
        hideLinksBlock && hideNewsletterBlock && styles.contentCentered,
        ctaParam === 'none' && noNavParam === 'all' && styles.darkerFooter,
      )}
    >
      <div className={styles.footerContainer}>
        <div className={styles.footerWrapper}>
          <div className={clsx(styles.columnContainer, styles.logo)}>
            <div className={styles.companyWrapper}>{renderLogoBox()}</div>
          </div>
          {!hideLinksBlock && navigationLinks && navigationLinks.length > 0 && (
            <div className={clsx(styles.columnContainer, styles.navigation)}>
              <span className={styles.subSectionTitle}>Navigation</span>
              {navigationLinks.map((link, i) => (
                <React.Fragment key={i}>{renderLinks(link)}</React.Fragment>
              ))}
            </div>
          )}
          {!hideNewsletterBlock && newsletterBlock && (
            <div className={clsx(styles.columnContainer, styles.newsletter)}>
              <div className={styles.newsletterWrapper}>
                <span className={styles.subSectionTitle}>{newsletterBlock.title}</span>
                <div className={styles.newsletterInputWrapper}>
                  <EmailInput
                    buttonReadyText="Subscribe"
                    buttonSubmittedText="Subscribed!"
                    buttonSubmittingText="Subscribing"
                    onSubmit={() => {
                      withDataLayer(dataLayer => {
                        dataLayer.push({
                          event: 'form-submit',
                          type: 'email-input',
                          id: 'newsletter',
                          path: location.pathname,
                        });
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <hr className={clsx(styles.divider, styles.mobile)}></hr>

          {!hideLinksBlock && legalLinks && legalLinks.length > 0 && (
            <div className={clsx(styles.columnContainer, styles.legal)}>
              <span className={clsx(styles.subSectionTitle, styles.legalTitle)}>Legal</span>
              {legalLinks.map((link, i) => (
                <React.Fragment key={i}>{renderLinks(link)}</React.Fragment>
              ))}
            </div>
          )}
        </div>
        <hr className={clsx(styles.divider, styles.desktop)}></hr>
        {renderCopyright()}
      </div>
    </footer>
  );
};

export default Footer;
