import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import Slider from '../Slider';
import * as styles from './LogosModule.module.scss';

const ELEMENT_ANIMATION_DURATION = 1.5;

interface Logo {
  darkLogo: SanityImageType;
  whiteLogo: SanityImageType;
}

export type LogosModuleProps = {
  pageTheme?: ModuleBackgroundColor;
  backgroundColor: ModuleBackgroundColor;
  title: string;
  className?: string;
} & (
  | {
      useClientBrandsLogos: false;
      logosToUse?: never;
      referenceLogos?: never;
      logos: Array<Logo>;
    }
  | {
      useClientBrandsLogos: true;
      logosToUse: 'all';
      referenceLogos?: never;
      logos?: never;
    }
  | {
      useClientBrandsLogos: true;
      logosToUse: 'chooseManually';
      referenceLogos: Array<Logo>;
      logos?: never;
    }
);

interface QueryData {
  allSanityClientBrand: {
    nodes: Array<{
      darkLogo: SanityImageType;
      whiteLogo: SanityImageType;
    }>;
  };
}

export function getModuleBgColor(props: LogosModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function LogosModule(props: LogosModuleProps & CommonModuleProps): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityClientBrand(sort: { orderRank: ASC }) {
        nodes {
          darkLogo {
            ...SanityImage
          }
          whiteLogo {
            ...SanityImage
          }
        }
      }
    }
  `);

  const groqQuery = groq`{
  "allSanityClientBrand": {
    "nodes": *[_type == "clientBrand"] | order(orderRank asc) {
      ...
    }
  }
}`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const {
    backgroundColor,
    title,
    useClientBrandsLogos,
    logosToUse,
    referenceLogos,
    logos,
    className,
    moduleId,
    pageTheme,
    previousModuleBgColor,
  } = props;

  const logosToShow = useClientBrandsLogos
    ? logosToUse === 'all'
      ? data.allSanityClientBrand.nodes
      : referenceLogos
    : logos;

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      title={title}
    >
      <div className={styles.sliderWrapper}>
        <Slider
          animationDuration={ELEMENT_ANIMATION_DURATION * logosToShow.length + 1}
          stopAnimationOnLargerScreens
          className={styles.slider}
          elementsClassName={styles.elementsContainer}
        >
          <div className={styles.logosContainer}>
            {logosToShow.map((logo, i) => (
              <div className={styles.logoContainer} key={i}>
                <Image
                  eagerLoading
                  image={
                    backgroundColor === 'dark' ||
                    (backgroundColor === 'pageColor' && pageTheme === 'dark')
                      ? logo.whiteLogo
                      : logo.darkLogo
                  }
                  className={styles.logo}
                />
              </div>
            ))}
          </div>
        </Slider>
      </div>
    </ModuleLayout>
  );
}

export default LogosModule;
