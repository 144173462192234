import ArticlePage from '../templates/ArticlePage';
import CaseStudyPage from '../templates/CaseStudyPage';
import InsightPage from '../templates/InsightPage';
import Page from '../templates/Page';

export const pageTemplatesByPath = {
  './src/templates/ArticlePage.tsx': ArticlePage,
  './src/templates/CaseStudyPage.tsx': CaseStudyPage,
  './src/templates/InsightPage.tsx': InsightPage,
  './src/templates/Page.tsx': Page,
};
