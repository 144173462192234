// extracted by mini-css-extract-plugin
export var arrow = "Card-module--arrow--7adbe";
export var category = "Card-module--category--dd9e5";
export var container = "Card-module--container--38d45";
export var externalLink = "Card-module--externalLink--25205";
export var hideImageOnMobile = "Card-module--hideImageOnMobile--b88ed";
export var image = "Card-module--image--18355";
export var imageContainer = "Card-module--imageContainer--f7a55";
export var link = "Card-module--link--69449";
export var text = "Card-module--text--8f711";
export var textContainer = "Card-module--textContainer--bffc9";
export var title = "Card-module--title--b6bf3";
export var withMarginTop = "Card-module--withMarginTop--52c4d";
export var withTallerImage = "Card-module--withTallerImage--b4a30";