/**
 * Creates a new type by adding a required property to an existing type.
 * @param {Type} Type - The existing type.
 * @param {Key} Key - The name of the property to make required.
 * @returns The new type with the added required property.
 * @example
 * Define a type with an optional property
 * type Person = {
 *   name: string;
 *   age?: number;
 * };
 *
 * Create a new type with a required age property
 * type RequiredAgePerson = WithRequiredProperty<Person, 'age'>;
 *
 * Usage
 * const person1: RequiredAgePerson = { name: 'Alice', age: 30 }; // Valid
 * const person2: RequiredAgePerson = { name: 'Bob' }; // Error: age is required
 */
export type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

/**
 *  Creates a new type by overriding the type of a property of an existing object type with the type of the corresponding property in another object type.
 *  @template T - The type of the target object to override.
 *  @template U - The type of the source object from which we are taking the properties to override on the target object.
 *  @returns A new type with properties of T overridden by properties of U.
 *  @example
 *  Define an object type:
 *  type Person = {
 *    name: string;
 *    age: number;
 *    hasGlasses: boolean;
 *  };
 *
 *  Create a new type by overriding any property(ies)
 *  type Result = Override<Person, {age?: number}>;
 *
 *  Usage
 *  const person1: Result = { name: 'Alice', age: 30, hasGlasses: true }; // Valid
 *  const person2: Result = { name: 'Bob', hasGlasses: false}; // Valid
 *  const person3: Result = { name: 'Charlie' }; // Error: hasGlasses is required
 */
export type Override<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

/**
 * Returns true if the value is truthy (i.e. not false, undefined, null, 0, or '').
 * Useful to use in .filter(truthy) as it actually returns the correct type (an array without falsy values).
 *
 * @param {T} value - The value to check for truthiness.
 * @returns {boolean} - Returns true if the value is truthy, false otherwise.
 * @example
 *   truthy("This is a truthy value"); // true
 *   truthy(0); // false
 */
export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

/**
 * Creates a new type by returning a union type of all values of an existing object type.
 * @param {Object} T - The existing object type whose values need to be extracted.
 * @returns A union type of all the values of the supplied object type.
 * @example
 * Define an object type
 * type Pet = {
 *   name: string;
 *   age: number;
 * };
 *
 * Create a union type of all the values in the object type using ValuesType
 * type PetValues = ValuesType<Pet>; // string | number
 *
 * Usage
 * const petValues: PetValues[] = ["Max", 5, "Fluffy", 4]; // Valid
 */
export type ValuesType<T> = T[keyof T];
