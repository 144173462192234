// extracted by mini-css-extract-plugin
export var container = "VideoReportsModule-module--container--a4416";
export var icon = "VideoReportsModule-module--icon--cbe62";
export var iconOnTopLeftCorner = "VideoReportsModule-module--iconOnTopLeftCorner--ff5ca";
export var line = "VideoReportsModule-module--line--40436";
export var name = "VideoReportsModule-module--name--dd154";
export var nameDivider = "VideoReportsModule-module--nameDivider--46de5";
export var playIconContainer = "VideoReportsModule-module--playIconContainer--673af";
export var playIconOverlay = "VideoReportsModule-module--playIconOverlay--74caf";
export var playIconWrapper = "VideoReportsModule-module--playIconWrapper--796e7";
export var subtitle = "VideoReportsModule-module--subtitle--76cdc";
export var videoReportContainer = "VideoReportsModule-module--videoReportContainer--8461b";
export var videoReportsContainer = "VideoReportsModule-module--videoReportsContainer--ffd3e";