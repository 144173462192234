import React from 'react';

import { useGlobalState } from '../../../state/globalStateContext';
import { ModuleBackgroundColor } from '../../ModulesContent';
import ModuleLayout from '../ModuleLayout';
import * as styles from './NumberedListModule.module.scss';

export type NumberedListModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  text: string;
  numberedListItems: Array<{
    title: string;
    titleWithCloserParameter?: string;
    text: string;
  }>;
  className?: string;
};

export function getModuleBgColor(props: NumberedListModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

const NumberedListModule = ({
  title,
  text,
  numberedListItems,
}: NumberedListModuleProps): React.ReactElement => {
  const { closer } = useGlobalState();

  return (
    <ModuleLayout childrenClassName={styles.sectionContainer}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.titleDivider}></div>
        <p className={styles.text}>{text}</p>
      </div>
      <div className={styles.listContainer}>
        {numberedListItems.map((item, i) => (
          <div key={i} className={styles.listItem}>
            <span className={styles.number}>{i + 1}</span>
            <div className={styles.listItemTextContainer}>
              <h3 className={styles.itemTitle}>
                {item.titleWithCloserParameter && closer
                  ? item.titleWithCloserParameter.replace('{closerName}', closer.name)
                  : item.title}
              </h3>
              {item.text && <p className={styles.itemText}>{item.text}</p>}
            </div>
          </div>
        ))}
      </div>
    </ModuleLayout>
  );
};

export default NumberedListModule;
