exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/ArticlePage.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-case-study-page-tsx": () => import("./../../../src/templates/CaseStudyPage.tsx" /* webpackChunkName: "component---src-templates-case-study-page-tsx" */),
  "component---src-templates-insight-page-tsx": () => import("./../../../src/templates/InsightPage.tsx" /* webpackChunkName: "component---src-templates-insight-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-pages-blog-tsx": () => import("./../../../src/templates/pages/blog.tsx" /* webpackChunkName: "component---src-templates-pages-blog-tsx" */),
  "component---src-templates-pages-case-studies-tsx": () => import("./../../../src/templates/pages/case-studies.tsx" /* webpackChunkName: "component---src-templates-pages-case-studies-tsx" */)
}

