import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './TeamGridModule.module.scss';

export const TeamMemberFragment = graphql`
  fragment TeamMember on SanityHpWebsiteTeamMember {
    name
    position
    image {
      ...SanityImage
    }
  }
`;

export interface TeamMember {
  name: string;
  position: string;
  image: SanityImageType;
}

export interface TeamGridModuleProps {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  text: string;
  referencesToUse: 'all' | 'chooseManually';
  teamMemberReferences: Array<TeamMember>;
  className?: string;
}

interface QueryData {
  allSanityHpWebsiteTeamMember: {
    nodes: Array<TeamMember>;
  };
}

export function getModuleBgColor(props: TeamGridModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function TeamGridModule(props: TeamGridModuleProps & CommonModuleProps): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityHpWebsiteTeamMember(sort: { orderRank: ASC }) {
        nodes {
          ...TeamMember
        }
      }
    }
  `);

  const groqQuery = groq`{
    "allSanityHpWebsiteTeamMember": {
      "nodes": *[_type == "hp-website-teamMember"] | order(orderRank asc) {
        ...
      }
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const {
    backgroundColor,
    title,
    text,
    referencesToUse,
    teamMemberReferences,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      title={title}
      text={text}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      childrenClassName={styles.teamMembersContainer}
    >
      {(referencesToUse === 'chooseManually' && teamMemberReferences
        ? teamMemberReferences
        : data.allSanityHpWebsiteTeamMember.nodes
      ).map((teamMember, i) => (
        <div className={styles.teamMemberContainer} key={i}>
          <div className={styles.imageContainer}>
            <Image
              image={teamMember.image}
              className={styles.image}
              alt={'Profile image of ' + teamMember.name}
              width={890}
              height={890}
              cover
            />
          </div>
          <div className={styles.textContainer}>
            <h3 className={styles.name}>{teamMember.name}</h3>
            <p className={styles.position}>{teamMember.position}</p>
          </div>
        </div>
      ))}
    </ModuleLayout>
  );
}

export default TeamGridModule;
