import React, { ReactNode } from 'react';
import arrow from '../../images/button_arrow.svg';
import { clsx } from '../../utils/utils';

import * as styles from './Button.module.scss';

export interface ButtonProps extends Omit<React.HTMLAttributes<HTMLElement>, 'children'> {
  children: string | ReactNode;
  outlined?: boolean;
  backgroundColor?: 'white';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'submit';
  withoutIconAnimation?: boolean;
  largeButton?: boolean;
  className?: string;
}

const Button = ({
  children,
  outlined,
  backgroundColor,
  onClick,
  type,
  withoutIconAnimation,
  largeButton,
  className,
  ...restProps
}: ButtonProps): React.ReactElement => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        styles.button,
        outlined && styles.outlined,
        backgroundColor === 'white' && styles.white,
        withoutIconAnimation && styles.withoutIconAnimation,
        largeButton && styles.largeButton,
        className,
      )}
      type={type === 'submit' ? 'submit' : 'button'}
      {...restProps}
    >
      {children}
      {largeButton && backgroundColor === 'white' && (
        <img src={arrow} alt="" className={styles.arrow} />
      )}
    </button>
  );
};

export default Button;
