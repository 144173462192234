import React from 'react';

const DEFAULT_STARS_SIZE = 24;

export interface RatingStarsProps {
  rating: 1 | 2 | 3 | 4 | 5;
  className?: string;
  fillColor?: string;
  starsSize?: number;
  gapSize?: number;
}

const RatingStars = ({
  rating,
  className,
  fillColor = 'var(--color-yellow)',
  starsSize = DEFAULT_STARS_SIZE,
  gapSize = 0,
}: RatingStarsProps): React.ReactElement => {
  const width = starsSize * 5 + gapSize * 4;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={starsSize}
      viewBox={`0 0 ${width} ${starsSize}`}
      aria-label="rating-stars"
      role="img"
    >
      <symbol id="star">
        <path
          transform={`scale(${starsSize / DEFAULT_STARS_SIZE})`}
          fill="white"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0003 18.6903L17.3483 21.501C18.3896 22.0477 19.6056 21.1637 19.4069 20.005L18.3856 14.0503L22.7136 9.83565C23.5563 9.01432 23.0923 7.58365 21.9269 7.41432L15.9483 6.54498L13.2749 1.12498C12.7549 0.0703177 11.2496 0.0703177 10.7296 1.12498L8.05495 6.54498L2.07628 7.41432C0.912279 7.58365 0.446945 9.01432 1.28961 9.83565L5.61761 14.0503L4.59628 20.005C4.39761 21.1637 5.61361 22.049 6.65495 21.501L12.0029 18.6903H12.0003Z"
        ></path>
      </symbol>
      <mask id="stars">
        <use href="#star" transform={`translate(${(starsSize + gapSize) * 0} 0)`} />
        <use href="#star" transform={`translate(${(starsSize + gapSize) * 1} 0)`} />
        <use href="#star" transform={`translate(${(starsSize + gapSize) * 2} 0)`} />
        <use href="#star" transform={`translate(${(starsSize + gapSize) * 3} 0)`} />
        <use href="#star" transform={`translate(${(starsSize + gapSize) * 4} 0)`} />
      </mask>
      <rect
        x="0"
        y="0"
        width={`${(rating / 5) * 100}%`}
        height="100%"
        fill={fillColor}
        mask="url(#stars)"
      />
      <rect
        x={`${(rating / 5) * 100}%`}
        y="0"
        width="100%"
        height="100%"
        fill="#E4E4E5"
        mask="url(#stars)"
      />
    </svg>
  );
};

export default RatingStars;
