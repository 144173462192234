import React from 'react';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ModuleLayout from '../ModuleLayout';
import Slider from '../Slider';
import * as styles from './WordSliderModule.module.scss';

const ELEMENT_ANIMATION_DURATION = 4.5;

export interface WordSliderModuleProps {
  backgroundColor: ModuleBackgroundColor;
  firstRow: {
    words: Array<string>;
  };
  secondRow: {
    words: Array<string>;
  };
  className?: string;
}

export function getModuleBgColor(props: WordSliderModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function WordSliderModule(props: WordSliderModuleProps & CommonModuleProps): React.ReactElement {
  const { backgroundColor, firstRow, secondRow, className, moduleId, previousModuleBgColor } =
    props;

  return (
    <ModuleLayout
      id={moduleId}
      className={clsx(className, styles.container, backgroundColor === 'dark' && styles.lightColor)}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      contentClassName={styles.contentContainer}
      childrenClassName={styles.wordsContainer}
    >
      <div className={styles.moduleDivider}></div>
      <div className={styles.rowContainer}>
        <Slider
          animationDuration={ELEMENT_ANIMATION_DURATION * firstRow.words.length + 100}
          className={styles.slider}
        >
          {firstRow.words.map((word, i) => (
            <h2 key={i} className={clsx(styles.word)}>
              {word}
              <div className={styles.divider} />
            </h2>
          ))}
        </Slider>
      </div>
      <div className={styles.rowContainer}>
        <Slider
          animationDuration={ELEMENT_ANIMATION_DURATION * secondRow.words.length + 100}
          animationDirection="left"
          className={styles.slider}
        >
          {firstRow.words.map((word, i) => (
            <h2 key={i} className={clsx(styles.word)}>
              {word}
              <div className={styles.divider} />
            </h2>
          ))}
        </Slider>
      </div>
      <div className={clsx(styles.moduleDivider, styles.bottomDivider)}></div>
    </ModuleLayout>
  );
}

export default WordSliderModule;
