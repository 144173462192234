import React, { useState } from 'react';
import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { isNestedEntry, NestedEntry, SingleEntry } from '../../graphql-fragments/navigationMenu';
import { capitalize, sum } from '../../utils/nodash';
import { getUrlFromVersatileLink } from '../../utils/sanity';

import { Helmet } from 'react-helmet';
import { AUTHOR_NAME } from '../../constants';
import { checkIsInternalUrl, clsx } from '../../utils/utils';
import ButtonLink from './ButtonLink';
import DropDownMenu from './DropDownMenu';
import * as styles from './Header.module.scss';
import Menu from './Menu';
import MenuButton from './MenuButton';
import SmartLink from './SmartLink';

export interface HeaderProps {
  sticky?: boolean;
  logoImageUrl: string;
  entries: Array<SingleEntry | NestedEntry>;
  ctaButton?: ButtonLinkType;
  ctaButtonAnchor?: string;
  currentUrl?: string;
  hideMenuEntries?: boolean;
}

const Header = ({
  sticky,
  logoImageUrl,
  entries,
  ctaButton,
  ctaButtonAnchor,
  currentUrl,
  hideMenuEntries,
}: HeaderProps): React.ReactElement => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // NOTE: this could probably be replaced by calculating the menu size
  // automatically with javascript.
  // TODO Check these sizes on each project
  const MENU_GAP_SIZE = 24; // spacing-medium
  const MENU_ENTRY_PADDING_SIZE = 8; // spacing-x-small
  const MENU_CHAR_SIZE = 10;
  const MENU_EXTERNAL_URL_ICON_SIZE = 20;
  // Small menus are shown from tablet vertical upwards
  const MEDIUM_MENU_MIN_SIZE = 500; // Fits only from table horizontal upwards
  const LARGE_MENU_MIN_SIZE = 700; // Fits only from laptop upwards
  const HUGE_MENU_MIN_SIZE = 800; // Doesn't fit anywhere

  // Calculates the menu size so we can inject corresponding classes
  // to hide the menu when it doesn't fit anymore
  const menuApproximateSize =
    entries.filter(
      entry => !isNestedEntry(entry) && !checkIsInternalUrl(getUrlFromVersatileLink(entry)),
    ).length *
      MENU_EXTERNAL_URL_ICON_SIZE +
    sum(entries.map(({ title }) => title.length * MENU_CHAR_SIZE + MENU_ENTRY_PADDING_SIZE * 2)) +
    (entries.length - 1) * MENU_GAP_SIZE;
  const menuSizeCategory =
    menuApproximateSize >= HUGE_MENU_MIN_SIZE
      ? 'huge'
      : menuApproximateSize >= LARGE_MENU_MIN_SIZE
      ? 'large'
      : menuApproximateSize >= MEDIUM_MENU_MIN_SIZE
      ? 'medium'
      : 'small';

  const ctaButtonUrl =
    ctaButton &&
    (ctaButtonAnchor &&
    currentUrl?.replace(/\/+$/, '') === getUrlFromVersatileLink(ctaButton).replace(/\/+$/, '')
      ? '#' + ctaButtonAnchor.replace('#', '')
      : getUrlFromVersatileLink(ctaButton));

  return (
    <>
      {sticky && (
        <Helmet>
          <html className={styles.html}></html>
        </Helmet>
      )}
      <header
        className={clsx(
          styles.header,
          styles['headerWithMenu' + capitalize(menuSizeCategory)],
          sticky && styles.stickyHeader,
        )}
      >
        <div className={clsx(styles.headerContainer, hideMenuEntries && styles.noNavHeader)}>
          <SmartLink to="/" onClick={() => setIsMenuOpen(false)} className={styles.logoWrapper}>
            <img className={styles.logoContainer} src={logoImageUrl} alt="Hook Point logo" />
            <div className={styles.visuallyHidden}>{AUTHOR_NAME}</div>
          </SmartLink>

          {!hideMenuEntries && (
            <>
              <Menu
                entries={entries}
                className={clsx(styles.desktopMenu, !ctaButton && styles.centerAlignedMenu)}
              ></Menu>

              <MenuButton
                className={styles.menuButton}
                isMenuOpen={isMenuOpen}
                onTouch={() => setIsMenuOpen(!isMenuOpen)}
              ></MenuButton>
            </>
          )}

          {ctaButton && ctaButtonUrl && (
            <ButtonLink to={{ url: ctaButtonUrl }} linkClassName={styles.ctaButton}>
              {ctaButton.title}
            </ButtonLink>
          )}

          {!hideMenuEntries && (
            <div className={styles.dropDownMenuWrapperWindow}>
              <div
                className={clsx(
                  styles.dropDownMenuWrapper,
                  isMenuOpen ? styles.menuOpen : styles.menuClosed,
                )}
              >
                <DropDownMenu
                  entries={entries}
                  ctaButtonText={ctaButton?.title}
                  ctaButtonUrl={ctaButtonUrl}
                  onEntryClick={() => setIsMenuOpen(false)}
                  onCtaButtonClick={() => setIsMenuOpen(false)}
                ></DropDownMenu>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
