import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React from 'react';
import { FaHeadphones, FaPencilAlt, FaVideo } from 'react-icons/fa';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { capitalize } from '../../../utils/nodash';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import { CardType } from '../Card';
import ItemListing from '../ItemListing';
import ModuleLayout from '../ModuleLayout';
import * as styles from './FeaturedInModule.module.scss';

interface MediaHighlight {
  _id: string;
  title: string;
  url: string;
  image: SanityImageType;
  mediaType: 'podcast' | 'article' | 'video';
}

export type FeaturedInModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  className?: string;
} & (
  | {
      referencesToUse: 'all';
      mediaHighlights?: never;
    }
  | {
      referencesToUse: 'chooseManually';
      mediaHighlights: Array<MediaHighlight>;
    }
);

interface QueryData {
  allSanityMediaHighlight: {
    nodes: Array<MediaHighlight>;
  };
}

export function getModuleBgColor(props: FeaturedInModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function FeaturedInModule(props: FeaturedInModuleProps & CommonModuleProps): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityMediaHighlight(sort: { orderRank: ASC }) {
        nodes {
          _id
          title
          url
          image {
            ...SanityImage
          }
          mediaType
        }
      }
    }
  `);

  const groqQuery = groq`{
    "allSanityMediaHighlight": {
      "nodes": *[_type == "mediaHighlight"] | order(orderRank asc) {
        ...
      }
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const {
    backgroundColor,
    title,
    referencesToUse,
    mediaHighlights,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  const categoryConfig = {
    article: { icon: <FaPencilAlt />, cta: 'Read' },
    podcast: { icon: <FaHeadphones />, cta: 'Listen' },
    video: { icon: <FaVideo />, cta: 'Watch' },
  };

  const listingItems = (
    referencesToUse === 'chooseManually' ? mediaHighlights : data.allSanityMediaHighlight.nodes
  ).map(mediaHighlight => ({
    _id: mediaHighlight._id,
    image: mediaHighlight.image,
    title: mediaHighlight.title,
    category: (
      <>
        {categoryConfig[mediaHighlight.mediaType].icon}
        {capitalize(mediaHighlight.mediaType)}
      </>
    ),
    link: { url: mediaHighlight.url },
    linkText: categoryConfig[mediaHighlight.mediaType].cta + ' ' + mediaHighlight.mediaType,
    linkIcon: 'externalLink',
  })) as Array<CardType & { _id: string }>;

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      title={title}
    >
      <div className={styles.listingContainer}>
        <ItemListing items={listingItems} />
      </div>
    </ModuleLayout>
  );
}

export default FeaturedInModule;
