import BlockContent from '@sanity/block-content-to-react';
import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import { usePreviewData } from '../../preview/previewUtils';
import serializers from '../../serializers';
import { RawPortableText } from '../../types/types';
import ModalOverlay, { ModalOverlayProps } from './ModalOverlay';
import * as styles from './TermsAndConditionsModal.module.scss';

export interface TermsAndConditionsModalProps extends Omit<ModalOverlayProps, 'children'> {
  className?: string;
}

interface QueryData {
  sanitySettings: {
    termsAndConditions: {
      title: string;
      introText: string;
      _rawText: RawPortableText;
    };
  };
}

const TermsAndConditionsModal = ({
  open,
  onClose,
  className,
  ...restProps
}: TermsAndConditionsModalProps): React.ReactElement => {
  const staticData: QueryData = useStaticQuery(
    graphql`
      {
        sanitySettings {
          termsAndConditions {
            title
            introText
            _rawText(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    `,
  );

  const groqQuery = groq`{
        "sanitySettings": *[_id == "settings-singleton"][0] {
         ...
        }
      }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  const { title, introText, _rawText } = staticData.sanitySettings.termsAndConditions;

  return (
    <ModalOverlay
      open={open}
      onClose={onClose}
      {...restProps}
      modalClassName={styles.modal}
      modalContainerClassName={styles.modalContentContainer}
      title={title}
      introText={introText}
    >
      <BlockContent
        renderContainerOnSingleChild
        blocks={_rawText}
        serializers={serializers}
        className={styles.modalText}
      />
    </ModalOverlay>
  );
};

export default TermsAndConditionsModal;
