// extracted by mini-css-extract-plugin
export var arrow = "FeaturedArticlesModule-module--arrow--c3eac";
export var articleContainer = "FeaturedArticlesModule-module--articleContainer--dc796";
export var articleInfoContainer = "FeaturedArticlesModule-module--articleInfoContainer--14641";
export var category = "FeaturedArticlesModule-module--category--7fba0";
export var columnContainer = "FeaturedArticlesModule-module--columnContainer--78a00";
export var content = "FeaturedArticlesModule-module--content--40396";
export var contentContainer = "FeaturedArticlesModule-module--contentContainer--767a4";
export var description = "FeaturedArticlesModule-module--description--36176";
export var image = "FeaturedArticlesModule-module--image--0045a";
export var imageContainer = "FeaturedArticlesModule-module--imageContainer--5bc93";
export var info = "FeaturedArticlesModule-module--info--a8697";
export var link = "FeaturedArticlesModule-module--link--fb734";
export var linkAndInfoContainer = "FeaturedArticlesModule-module--linkAndInfoContainer--df2fc";
export var overlay = "FeaturedArticlesModule-module--overlay--7f32b";
export var textContainer = "FeaturedArticlesModule-module--textContainer--7cc2c";
export var title = "FeaturedArticlesModule-module--title--0f6bd";