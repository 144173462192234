import React from 'react';
import { NestedEntry, SingleEntry, isNestedEntry } from '../../graphql-fragments/navigationMenu';
import { clsx } from '../../utils/utils';
import ButtonLink from './ButtonLink';
import * as styles from './DropDownMenu.module.scss';
import MenuEntry from './MenuEntry';
import NestedMenuEntry from './NestedMenuEntry';
interface DropDownMenuProps {
  entries: Array<SingleEntry | NestedEntry>;
  ctaButtonText?: string;
  ctaButtonUrl?: string;
  onEntryClick?: (
    entry: SingleEntry | NestedEntry,
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  onCtaButtonClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
}

const DropDownMenu = ({
  entries,
  ctaButtonText,
  ctaButtonUrl,
  className,
  onEntryClick,
  onCtaButtonClick,
}: DropDownMenuProps): React.ReactElement => {
  return (
    <div className={clsx(styles.container, className)}>
      {entries.map((entry, i) => (
        <React.Fragment key={i}>
          {isNestedEntry(entry) ? (
            <NestedMenuEntry
              key={i}
              entry={entry}
              className={styles.link}
              onClick={event => onEntryClick && onEntryClick(entry, event)}
            />
          ) : (
            <MenuEntry
              entry={entry}
              key={i}
              className={styles.link}
              onClick={event => onEntryClick && onEntryClick(entry, event)}
            />
          )}
        </React.Fragment>
      ))}
      <div className={styles.divider}></div>
      {ctaButtonText && ctaButtonUrl && (
        <ButtonLink
          to={{ url: ctaButtonUrl }}
          linkClassName={styles.ctaButton}
          onClick={onCtaButtonClick}
        >
          {ctaButtonText}
        </ButtonLink>
      )}
    </div>
  );
};

export default DropDownMenu;
