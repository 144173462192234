import React from 'react';
import { FaPlay } from 'react-icons/fa';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import videoArrow from '../../../images/videoArrow.svg';
import { useAnimatedTypewritingTextsInfo } from '../../../utils/hooks';
import { truthy } from '../../../utils/typescript';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ButtonLink from '../ButtonLink';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import SplitText from '../SplitText';
import Video from '../Video';
import * as styles from './AnimatedTextAndMediaModule.module.scss';

export type AnimatedTextAndMediaModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  introText?: string;
  animatedSentence?: {
    firstFixedWord: string;
    firstAnimatedWords: Array<{
      sentence: string;
    }>;
    secondFixedWord?: string;
    secondAnimatedWords?: Array<{
      sentence: string;
    }>;
  };
  buttonLink?: ButtonLinkType;
  className?: string;
} & (
  | {
      mediaType: 'image';
      image: SanityImageType;
      video?: never;
    }
  | {
      mediaType: 'video';
      image?: never;
      video: string;
    }
);

export function getModuleBgColor(props: AnimatedTextAndMediaModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function AnimatedTextAndMediaModule(
  props: AnimatedTextAndMediaModuleProps & CommonModuleProps,
): React.ReactElement {
  const {
    backgroundColor,
    mediaType,
    image,
    video,
    introText,
    animatedSentence,
    buttonLink,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  const animatedTextsInfo =
    animatedSentence &&
    useAnimatedTypewritingTextsInfo(
      [
        animatedSentence.firstAnimatedWords.map(animatedWords => animatedWords.sentence),
        animatedSentence.secondAnimatedWords &&
          animatedSentence.secondAnimatedWords.length > 0 &&
          animatedSentence.secondAnimatedWords.map(animatedWords => animatedWords.sentence),
      ].filter(truthy),
    );

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      contentClassName={styles.container}
      childrenClassName={styles.content}
    >
      <div
        className={clsx(
          styles.contentContainer,
          !animatedSentence && styles.withoutAnimatedSentence,
        )}
      >
        {animatedSentence && animatedTextsInfo && (
          <div className={styles.animatedSentenceContainer}>
            <h2 className={clsx(styles.title, styles.fixedWord, styles.firstWord)}>
              {animatedSentence.firstFixedWord}
            </h2>
            <SplitText
              as="h2"
              getCharStyle={({ index }) =>
                index < animatedTextsInfo.nVisibleCharsArray[0] ? { opacity: 1 } : { opacity: 0 }
              }
              className={clsx(styles.title, styles.animatedWords)}
            >
              {animatedSentence.firstAnimatedWords[animatedTextsInfo.currentSentenceIndex].sentence}
            </SplitText>
            {animatedSentence.secondFixedWord && (
              <h2 className={clsx(styles.title, styles.fixedWord)}>
                {animatedSentence.secondFixedWord}
              </h2>
            )}
            {animatedSentence.secondAnimatedWords &&
              animatedSentence.secondAnimatedWords.length > 0 && (
                <SplitText
                  as="h2"
                  getCharStyle={({ index }) =>
                    index < animatedTextsInfo.nVisibleCharsArray[1]
                      ? { opacity: 1 }
                      : { opacity: 0 }
                  }
                  className={clsx(styles.title, styles.animatedWords, styles.largerSentence)}
                >
                  {
                    animatedSentence.secondAnimatedWords[animatedTextsInfo.currentSentenceIndex]
                      .sentence
                  }
                </SplitText>
              )}
          </div>
        )}
        {introText && <div className={styles.divider}></div>}
        {introText && (
          <div className={styles.descriptionContainer}>
            <span className={styles.description}>
              {mediaType === 'video' && (
                <div className={styles.videoIconContainer}>
                  <FaPlay className={styles.videoIcon} aria-label="video-icon" role="img" />
                </div>
              )}
              {introText}
            </span>
            <img src={videoArrow} alt="" className={styles.videoArrow} />
          </div>
        )}
        {buttonLink && (
          <ButtonLink
            to={buttonLink}
            linkClassName={clsx(styles.buttonLink, styles.desktop)}
            className={clsx(styles.button, mediaType === 'video' && styles.withMarginLeft)}
          >
            {buttonLink.title}
          </ButtonLink>
        )}
      </div>

      {mediaType === 'video' && (
        <div className={styles.mediaContainer}>
          <Video url={video} className={styles.video} />
        </div>
      )}
      {mediaType === 'image' && (
        <div className={styles.mediaContainer}>
          <Image image={image} />
        </div>
      )}
      {buttonLink && (
        <ButtonLink
          to={buttonLink}
          linkClassName={clsx(styles.buttonLink, styles.mobile)}
          className={styles.button}
        >
          {buttonLink.title}
        </ButtonLink>
      )}
    </ModuleLayout>
  );
}

export default AnimatedTextAndMediaModule;
