import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ModuleLayout from '../ModuleLayout';
import TestimonialCarousel from '../TestimonialCarousel';
import * as styles from './TestimonialsModule.module.scss';

interface Testimonial {
  quote: string;
  author: string;
  position: string;
  image: SanityImageType;
}

export type TestimonialsModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  className?: string;
} & (
  | {
      referencesToUse?: 'all';
      testimonials?: never;
    }
  | {
      referencesToUse: 'chooseManually';
      testimonials: Array<Testimonial>;
    }
);

interface QueryData {
  allSanityTestimonial: {
    nodes: Array<Testimonial>;
  };
}

export function getModuleBgColor(props: TestimonialsModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function TestimonialsModule(
  props: TestimonialsModuleProps & CommonModuleProps,
): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityTestimonial: allSanityHpWebsiteTestimonial(sort: { orderRank: ASC }) {
        nodes {
          quote
          author
          position
          image {
            ...SanityImage
          }
        }
      }
    }
  `);

  const groqQuery = groq`{
    "allSanityTestimonial": {
      "nodes": *[_type == "hp-website-testimonial"] | order(orderRank asc) {
        ...
      }
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const {
    backgroundColor,
    referencesToUse,
    testimonials,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      childrenClassName={styles.contentContainer}
    >
      <TestimonialCarousel
        items={
          referencesToUse === 'chooseManually' ? testimonials : data.allSanityTestimonial.nodes
        }
      />
    </ModuleLayout>
  );
}

export default TestimonialsModule;
