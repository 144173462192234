// extracted by mini-css-extract-plugin
export var animatedWords = "AnimatedTextAndMediaModule-module--animatedWords--9809d";
export var button = "AnimatedTextAndMediaModule-module--button--b9cc4";
export var buttonLink = "AnimatedTextAndMediaModule-module--buttonLink--243a1";
export var container = "AnimatedTextAndMediaModule-module--container--af9d1";
export var content = "AnimatedTextAndMediaModule-module--content--5a178";
export var contentContainer = "AnimatedTextAndMediaModule-module--contentContainer--b330a";
export var description = "AnimatedTextAndMediaModule-module--description--5acb7";
export var descriptionContainer = "AnimatedTextAndMediaModule-module--descriptionContainer--4fd4a";
export var desktop = "AnimatedTextAndMediaModule-module--desktop--2dae2";
export var divider = "AnimatedTextAndMediaModule-module--divider--250fa";
export var firstWord = "AnimatedTextAndMediaModule-module--firstWord--953b9";
export var fixedWord = "AnimatedTextAndMediaModule-module--fixedWord--78dce";
export var largerSentence = "AnimatedTextAndMediaModule-module--largerSentence--7433e";
export var mediaContainer = "AnimatedTextAndMediaModule-module--mediaContainer--d7be6";
export var mobile = "AnimatedTextAndMediaModule-module--mobile--0ba2c";
export var title = "AnimatedTextAndMediaModule-module--title--26b2d";
export var videoArrow = "AnimatedTextAndMediaModule-module--videoArrow--97e82";
export var videoIcon = "AnimatedTextAndMediaModule-module--videoIcon--f24b0";
export var videoIconContainer = "AnimatedTextAndMediaModule-module--videoIconContainer--4d492";
export var withMarginLeft = "AnimatedTextAndMediaModule-module--withMarginLeft--972c9";
export var withoutAnimatedSentence = "AnimatedTextAndMediaModule-module--withoutAnimatedSentence--261b8";