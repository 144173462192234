// extracted by mini-css-extract-plugin
export var accordionsContainer = "AccordionModule-module--accordionsContainer--5310a";
export var accordionsWrapper = "AccordionModule-module--accordionsWrapper--5b219";
export var button = "AccordionModule-module--button--de18d";
export var buttonAndImagesContainer = "AccordionModule-module--buttonAndImagesContainer--d2c67";
export var buttonContainer = "AccordionModule-module--buttonContainer--c6bd3";
export var buttonsContainer = "AccordionModule-module--buttonsContainer--4b44d";
export var desktopImageContainer = "AccordionModule-module--desktopImageContainer--57ca9";
export var divider = "AccordionModule-module--divider--ac4b9";
export var image = "AccordionModule-module--image--3b4d9";
export var imageVisible = "AccordionModule-module--imageVisible--5cf95";
export var mobileImageContainer = "AccordionModule-module--mobileImageContainer--9d5c4";
export var text = "AccordionModule-module--text--4561d";