// extracted by mini-css-extract-plugin
export var calendar = "FormModule-module--calendar--5b3f0";
export var closerContainer = "FormModule-module--closerContainer--8a563";
export var closerImageContainer = "FormModule-module--closerImageContainer--b46c0";
export var closerName = "FormModule-module--closerName--5ee51";
export var closerSpan = "FormModule-module--closerSpan--496cf";
export var closerTextContainer = "FormModule-module--closerTextContainer--71cfb";
export var formContainer = "FormModule-module--formContainer--51d53";
export var smallerFont = "FormModule-module--smallerFont--353a7";
export var subtitle = "FormModule-module--subtitle--37255";
export var text = "FormModule-module--text--e5947";
export var textContainer = "FormModule-module--textContainer--18e33";
export var title = "FormModule-module--title--f81a0";
export var titleDivider = "FormModule-module--titleDivider--f57e0";