import React from 'react';
import { clsx } from '../../utils/utils';

import * as styles from './CategoryTag.module.scss';

export interface CategoryTagProps {
  categoryTitle: JSX.Element | string;
  className?: string;
}

const CategoryTag = ({ categoryTitle, className }: CategoryTagProps): React.ReactElement => {
  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.title}>{categoryTitle}</div>
    </div>
  );
};

export default CategoryTag;
