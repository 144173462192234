// extracted by mini-css-extract-plugin
export var arrow = "InsightCarousel-module--arrow--33ed1";
export var button = "InsightCarousel-module--button--df1de";
export var buttonGroup = "InsightCarousel-module--buttonGroup--5ba7d";
export var buttonIcon = "InsightCarousel-module--buttonIcon--04dce";
export var card = "InsightCarousel-module--card--dfa45";
export var carousel = "InsightCarousel-module--carousel--66cc8";
export var categoriesContainer = "InsightCarousel-module--categoriesContainer--a3aea";
export var description = "InsightCarousel-module--description--c874c";
export var imageContainer = "InsightCarousel-module--imageContainer--890fb";
export var link = "InsightCarousel-module--link--84e13";
export var textContainer = "InsightCarousel-module--textContainer--10bd6";
export var title = "InsightCarousel-module--title--015e2";
export var visuallyHidden = "InsightCarousel-module--visuallyHidden--5f76b";