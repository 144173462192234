import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { useGlobalState } from '../../../state/globalStateContext';
import { PageDocument, RawPortableText } from '../../../types/types';
import {
  useSourceUrl,
  useStoreFbclidOnLoad,
  useStoreReferrerOnLoad,
  useStoreUTMParamsOnLoad,
  useTrackingData,
} from '../../../utils/hooks';
import { uniqBy } from '../../../utils/nodash';
import { useStoreScheduleOnceCalendarOnLoad } from '../../../utils/projectUtils';
import { getPageDocumentUrl, getPortableTextAsString } from '../../../utils/sanity';
import {
  clsx,
  generateUniqueId,
  getLocalStorageMemoizedValue,
  replaceNewLinesWithBr,
  slugify,
  urlJoin,
  withDataLayer,
  wrapSquareBracketedWithEm,
} from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import Form, { FormField, FormFieldWithId } from '../Form';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import { Closer } from './CloserModule';
import * as styles from './FormModule.module.scss';

export type FormModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  formType: 'manualInputs' | 'scheduleOnce';
  title?: string;
  titleWithCloserParameter?: string;
  subtitle?: string;
  text?: string;
  className?: string;
} & (
  | {
      formType: 'manualInputs';
      form: {
        fields: Array<FormField>;
        submitButtonText?: string;
        thankYouScreen: {
          title: string;
          subtitle?: string;
          _rawText?: RawPortableText;
          scheduleACallButton?: ButtonLinkType;
        };
      };
      pageToGoAfterSubmit?: never;
    }
  | {
      formType: 'scheduleOnce';
      form?: never;
      pageToGoAfterSubmit: PageDocument;
    }
);

interface QueryData {
  allSanityCloser: {
    nodes: Array<Closer>;
  };
}

export function getModuleBgColor(props: FormModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function FormModule(props: FormModuleProps & CommonModuleProps): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityCloser(filter: { slug: { current: { ne: null } } }) {
        nodes {
          name
          slug {
            current
          }
          image {
            ...SanityImage
          }
          results {
            number
            subtitle
          }
          testimonial {
            testimonial
            author
            position
          }
          scheduleOnceCalendarId
        }
      }
    }
  `);

  const groqQuery = groq`{
    "allSanityMediaHighlight": {
      "nodes": *[_type == "mediaHighlight"] | order(orderRank asc) {
        ...
      }
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const {
    backgroundColor,
    formType,
    title,
    titleWithCloserParameter,
    subtitle,
    text,
    form,
    pageToGoAfterSubmit,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  const formFieldsWithIds: Array<FormFieldWithId> =
    formType === 'manualInputs'
      ? form.fields.map(formField => ({
          id: slugify(
            formField.fieldType === 'singleCheckbox'
              ? getPortableTextAsString(formField._rawText as RawPortableText)
              : formField.title,
          ),
          ...formField,
        }))
      : [];

  if (formFieldsWithIds!.length !== uniqBy(formFieldsWithIds!, 'id').length) {
    throw new Error(
      'Got duplicate ids in formFieldsWithIds: ' +
        JSON.stringify(formFieldsWithIds!.map(formField => formField.id)),
    );
  }

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useStoreUTMParamsOnLoad();
  useStoreFbclidOnLoad();
  useStoreReferrerOnLoad();

  const socalendarScheduleEventTriggered = useRef(false);
  useStoreScheduleOnceCalendarOnLoad([
    {
      paramName: 'closer',
      paramToCalendarId: Object.fromEntries(
        data.allSanityCloser.nodes.map(closer => [
          closer.slug.current,
          closer.scheduleOnceCalendarId,
        ]),
      ),
    },
  ]);
  const { scheduleOnceCalendar, closer, fbclid } = useGlobalState();

  const trackingData = useTrackingData();
  const sourceUrl = useSourceUrl();

  const onceHubData = {
    utm_source: trackingData.utmSource,
    utm_medium: trackingData.utmMedium,
    utm_campaign: trackingData.utmCampaign,
    utm_term: trackingData.utmTerm,
    utm_content: trackingData.utmContent,
    utm_device: trackingData.utmDevice,
    utm_target: trackingData.utmTarget,
    fbclid,
    websiteReferrer: trackingData.websiteReferrer,
    sourceUrl,
  };

  const filteredOnceHubData = Object.fromEntries(
    Object.entries(onceHubData).filter(([_, value]) => value !== null),
  ) as Record<string, string>;
  const onceHubQueryParams = new URLSearchParams(filteredOnceHubData).toString();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const closerParam = urlSearchParams.get('closer');

    if (formType === 'scheduleOnce' && scheduleOnceCalendar) {
      window.addEventListener(
        'message',
        event => {
          if (
            event.origin === 'https://go.oncehub.com' &&
            event.data?.bookingData &&
            event.data?.bookingData?.errorStatus === 0 &&
            !socalendarScheduleEventTriggered.current
          ) {
            withDataLayer(dataLayer => {
              dataLayer.push({
                event: 'socalendar-schedule',
                calendar: scheduleOnceCalendar,
                uniqueId: getLocalStorageMemoizedValue(
                  'randomly_generated_unique_id',
                  generateUniqueId,
                ),
              });
            });
            socalendarScheduleEventTriggered.current = true;
            window.location.href = urlJoin(
              getPageDocumentUrl(pageToGoAfterSubmit),
              closer ? '?closer=' + closerParam : '',
            );
          }
        },
        false,
      );
    }
  }, [scheduleOnceCalendar]);

  useEffect(() => {
    setHasLoaded(true);
  }, []);

  const moduleTitle =
    closer && titleWithCloserParameter
      ? titleWithCloserParameter.replace('{closerName}', closer.name)
      : title;

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      childrenClassName={styles.formContainer}
    >
      {formType === 'scheduleOnce' ? (
        <>
          <Helmet>
            {hasLoaded && (
              // ScheduleOnce embed
              <script type="text/javascript" src="https://cdn.oncehub.com/mergedjs/so.js"></script>
            )}
          </Helmet>
          <div className={styles.textContainer}>
            {closer && (
              <div className={styles.closerContainer}>
                <div className={styles.closerImageContainer}>
                  <Image image={closer.image} />
                </div>
                <div className={styles.closerTextContainer}>
                  <span className={styles.closerSpan}>Meeting with</span>
                  <span className={styles.closerName}>{closer.name}</span>
                </div>
              </div>
            )}
            <div className={styles.titleContainer}>
              {moduleTitle && (
                <h2 className={clsx(styles.title, moduleTitle.length > 40 && styles.smallerFont)}>
                  {wrapSquareBracketedWithEm(moduleTitle)}
                </h2>
              )}
              {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
              <div className={styles.titleDivider}></div>
            </div>
            {text && (
              <p className={clsx(styles.text, text.length > 350 && styles.smallerFont)}>
                {replaceNewLinesWithBr(text)}
              </p>
            )}
          </div>
          {scheduleOnceCalendar && (
            <div
              id={'SOIDIV_' + scheduleOnceCalendar}
              data-so-page={scheduleOnceCalendar}
              data-height="550"
              data-style="border: 1px solid #D8D8D8; min-width: 290px; max-width: 900px;"
              data-psz="10"
              data-so-qry-prm={onceHubQueryParams}
              className={styles.calendar}
            ></div>
          )}
        </>
      ) : (
        <Form
          formType="newsletter"
          fields={formFieldsWithIds}
          title={moduleTitle}
          subtitle={subtitle}
          text={text}
          thankYouScreen={form.thankYouScreen}
          className={styles.form}
        />
      )}
    </ModuleLayout>
  );
}

export default FormModule;
