// extracted by mini-css-extract-plugin
export var authorAvatar = "ReviewCard-module--authorAvatar--3d9d4";
export var authorContainer = "ReviewCard-module--authorContainer--f50a8";
export var authorImage = "ReviewCard-module--authorImage--23148";
export var authorInitials = "ReviewCard-module--authorInitials--4068b";
export var authorName = "ReviewCard-module--authorName--07d67";
export var bottomInfoContainer = "ReviewCard-module--bottomInfoContainer--b49ec";
export var category = "ReviewCard-module--category--65796";
export var container = "ReviewCard-module--container--1501e";
export var date = "ReviewCard-module--date--a1c36";
export var noAuthorImageContainer = "ReviewCard-module--noAuthorImageContainer--1f338";
export var readMoreLink = "ReviewCard-module--readMoreLink--fb6bc";
export var review = "ReviewCard-module--review--a69d2";
export var sourceImage = "ReviewCard-module--sourceImage--7d0e9";
export var topInfoContainer = "ReviewCard-module--topInfoContainer--0d5f0";